export const sorts = [
  {
    text: "Ascending",
    value: "asc",
  },
  {
    text: "Descending",
    value: "desc",
  },
  {
    text: "Recent",
    value: "recent",
  },
];

export const visibles = [
  {
    text: "Visible",
    value: "1",
  },
  {
    text: "Not Visible",
    value: "0",
  },
];

export const pageLimits = [
  {
    text: 1,
    value: 1,
  },
  {
    text: 5,
    value: 5,
  },
  {
    text: 10,
    value: 10,
  },
  {
    text: 20,
    value: 20,
  },
  {
    text: 30,
    value: 30,
  },
];
