<template>
  <v-container>
    
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="name!=''" class="h51">
              <span>Name</span>
            </h5>
            <v-text-field flat placeholder="Name"
              v-model="name" class="shrink"></v-text-field>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="email!=''" class="h51">
              <span>Email</span>
              <span class="input-invalid">{{ email_valid }}</span>
            </h5>
            <v-text-field flat placeholder="E-mail Address"
              v-model="email" class="shrink"></v-text-field>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="password!=''" class="h51">
              <span>Password</span>
              <span class="input-invalid">{{password_valid}}</span>
            </h5>
            <v-text-field flat class="shrink" :type="showPassword ? 'text' : 'password'"
              placeholder="Password" v-model="password" @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="passwordConfirm!=''" class="h51">
              <span>Confirm Password</span>
              <span class="input-invalid">{{passwordConfirm_valid}}</span>
            </h5>
            <v-text-field flat class="shrink" :type="showPasswordConfirm ? 'text' : 'password'"
              placeholder="Confirm Password" v-model="passwordConfirm" @click:append="showPasswordConfirm = !showPasswordConfirm"
              :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
          </div>
      <p v-if="error!=''" style="color: red">{{error}}<p>
      <v-btn type="submit" :disabled="!isRegisterReady" @click="register" color="primary">Register</v-btn>

      <h6>
        <span>Already have an account? </span>
        <span @click="goToRoute('/login')" class="sign-up mt-5" >Sign in</span>
      </h6>

  </v-container>
</template>

<script>
import { userSignUp } from "@/api/user";

export default {
  data: () => {
    return {
      userSignUp,
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      message: '',
      error: '',
      showPassword: false,
      showPasswordConfirm: false,
      email_valid: "",
      firstName_valid: "",
      lastName_valid: "",
      password_valid: "",
      passwordConfirm_valid: "",

    };
  },
  computed: {
    isLogin() {
      return this.checkLogin();
    }, 
    isRegisterReady() {
      return this.checkForm();
    },
  },
  watch: {
    email(value) {
      if (value != "") {
        this.message = "";
        this.validateEmail(value);
      }
    },
    password(value) {
      if (value != "") {
        this.message = "";
        this.validatePassword(value);
        if(this.passwordConfirm !=""){
          this.passwordMatch();
        }
      }
      
    },
    passwordConfirm(value) {
      this.passwordMatch();
    },
  },
  mounted() {
    //this.checkLogin()
  },
  methods: {
    register() {
        const data = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirm,
        };
        this.userSignUp(data).then((res) => {
          const { success, data } = res;
          if(success) {
            this.$store.dispatch('user/getToken', data.token);
            this.$store.dispatch('user/getData', data);
            localStorage.setItem('userData', data);
            localStorage.setItem('userID', data.user.user_id);
            localStorage.setItem('userToken', data.token);
            console.log("SJJSJs")
            this.goToRoute('/free-table');
          }else{
            this.error = res.message 
            this.name = "";
            this.email = "";
            this.password = "";
            this.passwordConfirm = "";

            setTimeout(() => {
              this.error = "";
            }, 3000);
          }
        });
    },
    checkLogin() {
      return (
        localStorage.getItem("userToken") != null
      )
    },
    passwordMatch() {
      if (this.password != this.passwordConfirm) {
        this.passwordConfirm_valid = "Password does not match";
      } else {
        this.passwordConfirm_valid = "";
      }
    },
    goToRoute(route) {
      this.$router.push(route).catch((err) => {});
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_valid = "";
      } else {
        this.email_valid = " Email is not valid";
      }
    },
    validatePassword(password) {
      if (password.length < 8) {
        this.password_valid = " Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.password_valid = "";
            } else {
              this.password_valid = " At least one uppercase letter";
            }
          } else {
            this.password_valid = " At least one lowercase letter";
          }
        } else {
          this.password_valid = " At least one number";
        }
      }
    },
    checkForm() {
      return (
        this.name != "" &&
        this.email != "" &&
        this.password != "" &&
        this.passwordConfirm != "" &&
        this.passwordConfirm_valid == "" &&
        this.email_valid == ""
      );
    },
  },
};
</script>

<style scoped>
h6 {
  font-family: "Montserrat-Regular";
  margin-top: 20px;
}
.sign-up {
  text-decoration: underline;
  color: #427ab3;
  cursor: pointer;
  font-family: "Montserrat-Bold";
}
.input-invalid {
  color: red;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 600;
}

</style>
