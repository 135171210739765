import request from "@/utils/request";

export const getHistory = (data) => {
  return request.get("/api/history").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addHistory = (data) => {
  return request.post("/api/history", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const deleteOneHistory = (data) => {
  return request.delete(`/api/history/${data.id}`).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const deleteAllHistory = (data) => {
  return request.post("/api/history/destroy/all", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};


export const showAllHistory = (data) => {
  return request.post("/api/history/show/all", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};