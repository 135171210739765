<template>
  <div class="pa-3" style="background: #F0F0F0;">
    <HeaderPremium />
    <Premium />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import HeaderPremium from '@/components/Header/HeaderPremium'
import Premium from '@/views/AiWriter/Premium/index.vue'
export default {
  components: {
    Premium,
    Footer,
    HeaderPremium
  },
  data: () => {
    return {}
  }
}
</script>
