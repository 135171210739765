export const generateName = (item) => {
  let generateNamePrompt = `Please do not explain. 
    Please write down only the answer that is needed.
    Write down the ideal name of this command prompt make sure it's unique, catchy, and distinguishable from others.
    Make sure it's also specific as well.`;

  if (!item.command) {
    alert("Please fill in the 'Command' field first.");
    return;
  }
  const prompt = item.command + '\n' + generateNamePrompt;
  return prompt;
};

export const generateCommand = (item) => {
  let generateCommandPrompt= `I want you to create an instruction prompt based on *Input:*. 
    On the input, you can specify different parameters in order to fine tune the prompt that is generated. The format of the *Output:* must be like the example below.
    
    First, you must write the prompt based on the directions on the *Input*. Sometimes, additional parameters are provided in the instructions.
    Example:
    Prompt Length: Regular
    Result: "What are the best practices for designing a blended learning program that combines Learning Modalities, Learning Activities, and Technology Tools? How can we ensure that the program is accessible and engaging for the Target Audience?"
    Prompt Length: Concise
    Result: "Write a comprehensive plan for creating a business plan. Provide detailed information for each area."
    Prompt Length: 1 sentence
    Result: Help me write a detailed fiction novel with rich plot and world building.
    
    Important Parameters:
    Components: "dropdown", "textbox", "mixed"
    Result and Explanation: If selected "dropdown", all components should be forced generated to dropdowns with prefilled item choices. If selected "textbox", all components should be forced generated to textbox/ text area. If "mixed" you can generate both components.
    
    Guide: "Y", "N", "Yes", "No", "Minimal" "No Guide"
    Result and Explanation: Generate or do not generate Guide and Explanation components "{}" after each parameter. If "Minimal", do not generate a guide on self-explanatory fields, generate only on hard cases.
    
    Other examples of parameters are "Prompt Length", "Prompt Type", # of Parameters", "Required Parameters", and so on.
    
    Next, I want you to append additional structured information below the converted prompt. This information should include explanations or details for each key component. Use the following formats for structuring this information:
    
    1. For key components that require a list or a series of options, use the Text Area input field format. Enclose the title in square brackets and provide a description in curly braces below. For instance:
      Learning Modalities: [Learning Modalities]
      {List the preferred learning modalities here, e.g., online interactive sessions, offline assignments, etc. Specify modalities that align with your content and audience. Consider a mix of synchronous and asynchronous methods to cater to diverse learning styles.}
    
    2. For key components that require a selection from a fixed set of options, use the Dropdown Menu format. Use angle brackets to indicate the Dropdown Menu and include 'title' for the name of the dropdown menu field and 'items' for the choices within the dropdown menu. For example:
      Technology Tools: <title="Technology Tools"; items="Learning Management System (LMS)","Interactive Platforms","Virtual Collaboration Tools";>
      {Describe the technology tools essential for your program. Ensure that these tools are user-friendly and accessible to all members of your target audience.}
    
    Ensure that each structured piece of information is relevant to the parameter described and the prompt as a whole. The goal is to provide clarity and guidance on what the user is expected to describe or list for each key component.
    
    Additional Instructions:
    - Do not use special character line separators such as "-", "=", etc.
    - Do not ask for follow up or clarification questions and statements. Example: "Feel free to provide additional details or specific requirements."
    - Do not write prompt input parameter settings of any form.
    - Do not write statements such as "Based on the provided input, here is the generated prompt and additional structured information:" and other similar sentence.
    - Avoid using statements like "Use the dropdown menu" or "Fill out the textboxes" in the prompt since we're dealing with the end result.
    - Do not complain.
    - Present the output immediately without code blocks, quotation marks, or special formatting.
    - Avoid enclosing the output in code blocks, quotation marks, or any special formatting.
    - Present and Provide the output immediately, without greetings, apologies, introduction, prefactory explanations, afterwords, conclusion, or conclusion statements.
    - Avoid writing conclusions, afterwords, ending explanations, conclusion statements, and unsolicited advice.
    - Do not write and avoid writing the *Example Input:*, *Input*, and *Example Output:* section and its contents.
    - Do not write and avoid writing "_Output:_" "Output:" or "Output" etc at the start or beginning of the output result.
    - The output should be clear and easily interpretable by GPT 3.5 and other LLMs.
    - Ensure each structured piece of information is concise and directly related to the corresponding key component.
    - Clearly differentiate and Maintain clarity in distinguishing between Text Area input fields "[]" and Dropdown Menus "<>", and use them appropriately based on the nature of the information required. 
    - Place the guide "{}" with descriptions and information on a separate line below the corresponding parameter, not on the same line. If the value of the "Guide" parameter is "No", "N" or similar negative, do not do this. Do not place guide.
    - Use the dropdown menu field to guide the user with preset choices if providing an answer is challenging or better with options, to enhance the user experience. Such as giving "examples", technical details, niche specific details, product specific details, and so on.
    - Only write one output for each one input.
    
    *Example Input:*
    Strategize in designing blended learning programs.
    Prompt Length: Regular
    Prompt Type: Question Oriented
    # of Parameters: 4
    Required Parameters: Target Audience
    
    *Example Output:*
    What are the best practices for designing a blended learning program that combines Learning Modalities, Learning Activities, and Technology Tools? How can we ensure that the program is accessible and engaging for the Target Audience?
    
    Learning Modalities: [Learning Modalities]
    {List the preferred learning modalities here, e.g., online interactive sessions, offline assignments, etc. Specify modalities that align with your content and audience. Consider a mix of synchronous and asynchronous methods to cater to diverse learning styles.}
    
    Learning Activities: [Learning Activities]
    {Detail the learning activities you plan to include, such as case studies, quizzes, group projects, etc.}
    
    Technology Tools: <title="Technology Tools"; items="Learning Management System (LMS)","Interactive Platforms","Virtual Collaboration Tools";>
    {Describe the technology tools essential for your program. Ensure that these tools are user-friendly and accessible to all members of your target audience.}
  
    Target Audience: [Target Audience]
    {Define your target audience, including their background, learning needs, and preferences. You may consider their technical skills, prior knowledge, and learning goals.}
    
    *Example Input:*
    Help me write a nonfiction and self-development book.
    Prompt Length: Detailed
    Guide: No
    
    *Example Output*
    Craft a comprehensive plan for writing a nonfiction and self-development book, encompassing key elements such as Literary Resources, Writing Style, Target Audience, Research Methodology, Publishing Plan, and Personal Experience. Detail each aspect, ensuring the book is both informative and personally enriching.
    
    Literary Resources: [Literary Resources] 
    Writing Style: <title="Writing Style"; items="Narrative","Expository","Descriptive","Persuasive";> Target Audience: [Target Audience] 
    Research Methodology: [Research Methodology] 
    Publishing Plan: [Publishing Plan] 
    Personal Experience: [Personal Experience]
    
    *Example Input:*
    Write down a short guide on how to become a millionaire.
    
    *Example Output:*
    Create a concise guide outlining the steps to become a millionaire, focusing on Financial Planning, Investment Strategies, Income Streams, and Personal Development.
    
    Financial Planning: [Financial Planning] 
    {Define your financial goals, budget, and savings plan. Consider long-term objectives, emergency funds, and debt management strategies.}
  
    Investment Strategies: <title="Investment Strategies"; items="Stock Market","Real Estate","Business Ventures","Retirement Accounts";> 
    {Choose investment avenues that align with your risk tolerance and financial goals. Diversify your portfolio to balance risk and return.}
    
    Income Streams: [Income Streams] 
    {Identify potential sources of income beyond your primary job. Consider passive income opportunities, side businesses, or freelance work.}
    
    Personal Development: [Personal Development] 
    {Focus on enhancing skills and knowledge that contribute to financial success. This could include financial literacy, negotiation skills, or industry-specific expertise.}
    
    *Input:*`;

  if (!item) {
    alert("Please enter a description for the assistant.");
    return;
  }
  const prompt = generateCommandPrompt + '\n\n' + item;
  return prompt;
};

export const generateMainCategory = (item) => {
  let generateMainCategoryPrompt= `Please do not explain. Please write down only the answer that is needed.
    Write down the name of the Main category based on this prompt.`;

  if (!item.command) {
    alert("Please fill in the 'Command' field first.");
    return;
  }
  const prompt = item.command + '\n' + generateMainCategoryPrompt;
  return prompt;
};

export const generateSubCategory = (item) => {
  let generateSubCategoryPrompt= `Please do not explain. Please write down only the answer that is needed.
    Write down the name of the Sub category under main category based on this prompt.`;
  
    if (!item.command) {
    alert("Please fill in the 'Command' field first.");
    return;
  }
  const prompt = item.command + '\n' + generateSubCategoryPrompt;
  return prompt;
};

export const generateType = (item) => {
  let generateTypePrompt= `Please do not explain. Please write down only the answer that is needed.
    Write down the Prompt Type based on this prompt.
    Select from the choices:
    "Text" "Text+Image"`;
  if (!item.command) {
    alert("Please fill in the 'Command' field first.");
    return;
  }
  const prompt = item.command + '\n' + generateTypePrompt;
  return prompt;
};

export const generateDescription = (item) => {
  let generateDescriptionPrompt= `Write down a helpful and clear description of the prompt in the "Input". 
    Description Content Outline:
    1. Description Text (Write down description in sentence/paragraph format.)
    2. "No. of Parameters: " (List the total parameters.)
    3. "Parameters: " (Specify all parameter names.)
    
    Description Text Instructions:
    - Maximum sentences should only be 2. 
    - The sentence should begin with "This assistant". 
    - Do not state, write, include, or mention the specific parameter names under "Description Text" section.
    --> "Write a macro code to generate a 7-slide PowerPoint presentation that includes the following components: Slide Titles, Introduction Content, Key Message, Supporting Points, Visual Aids, Conclusion, and Call to Action"
    --> "The key elements include learning objectives, content sources, interaction methods, and assessment strategies."
  
    Additional Instructions:
    - Each section of Description Content should only be separated by only 1 line break. Do not use 2 or more line breaks ever.
    - Do not write and avoid writing the *Example Input:*, *Input*, and *Example Output:* section and its contents.
    - Do not write and avoid writing "_Output:_" "Output:" or "Output" etc at the start or beginning of the output result.
    - Parameters are indicated by and enclosed by brackets"[]" and pointed brackets "<>".
    - For the pointed brackets "<>", you must get the value of the "title" as the parameter name.
    - Do not write down the parameters in the first sentence of the description. Specify it only in the "Parameters:" section.
    - Each parameter in the "Parameters:" section in the description should be enclosed with quotation marks ("") and followed by a comma (,) to separate each parameter.
    - Only write one output for each one input.
    - Do not ask for follow up or clarification questions and statements. Example: "Feel free to provide additional details or specific requirements."
    - Do not complain.
    - Present the output immediately without code blocks, quotation marks, or special formatting.
    - Avoid enclosing the output in code blocks, quotation marks, or any special formatting.
    - Present and Provide the output immediately, without greetings, apologies, introduction, prefactory explanations, afterwords, conclusion, or conclusion statements.
    - Avoid writing conclusions, afterwords, ending explanations, conclusion statements, and unsolicited advice.
    - Do not write any of the existing statements and texts found in this prompt as output.
    
    *Example Input:*
    Create a structured outline for a book on habits, ensuring each chapter follows the A-Z naming convention, and includes Categories of Habits, Impact Analysis, Formation Strategies, Habit Tracking, and Personal Stories.
    
    Categories of Habits: [Categories of Habits] 
    {Divide the habits into distinct categories like productivity, health, and mindfulness. Align each category with the corresponding alphabet chapter.}
    
    Impact Analysis: [Impact Analysis] 
    {For each habit, analyze its potential positive and negative impacts on personal and professional life.}
    
    Formation Strategies: <title="Formation Strategies"; items="Cue-Routine-Reward","Tiny Habits","Habit Stacking","Behavioral Design";> 
    {Select a strategy for forming habits that are detailed in the respective chapters. These strategies should provide a practical framework for readers.}
    
    Habit Tracking: [Habit Tracking] 
    {Detail methods for tracking progress of habit formation, like journals or apps, and how they can be integrated into daily life.}
    
    Personal Stories: [Personal Stories] 
    {Include anecdotes or personal experiences that demonstrate the real-life application of building or breaking habits.}
    
    *Example Output:*
    This assistant helps you create a structured outline for a book on habits. The book is required to follow an A-Z naming convention for its chapters and each chapter should cover specific aspects related to habits , and for each chapter, it provides details on what content should be included. 
    No. of Parameters: 5
    Parameters: "Categories of Habits", "Impact Analysis", "Formation Strategies", "Habit Tracking", "Personal Stories"
    
    *Example Input:*
    Create a concise guide outlining the steps to become a millionaire, focusing on Financial Planning, Investment Strategies, Income Streams, and Personal Development.
    
    Financial Planning: [Financial Planning] 
    Investment Strategies: <title="Investment Strategies"; items="Stock Market","Real Estate","Business Ventures","Retirement Accounts";> 
    Income Streams: [Income Streams] 
    Personal Development: [Personal Development]
    
    *Example Output:*
    This assistant instructs the creation of a concise guide that outlines the steps to become a millionaire. The guide is structured into four main sections, and each section is expected to cover specific aspects of achieving this goal.
    No. of Parameters: 5
    Parameters: "Financial Planning", "Investment Strategies", "Income Streams", "Personal Development"
    
    *Example Input:*
    Develop a PowerPoint slide generator code snippet in your chosen programming language that dynamically creates content based on specified topics.
    
    Programming Language: [Programming Language] 
    {Specify the programming language to be used for scripting the PowerPoint content generator.}
    
    Topic Analysis Method: <title="Topic Analysis Method"; items="Natural Language Processing (NLP)","Keyword Extraction","Manual Input";> 
    {Define the method by which the generator will analyze and interpret the given topic to produce relevant content.}
    
    Content Generation Technique: [Content Generation Technique] 
    {Describe the technique or algorithm that will be employed to generate the slide content based on the analyzed topic.}
    
    Slide Design Template: [Slide Design Template] 
    {Select or create a slide design template that the code will apply to each generated slide for visual consistency.}
    
    Output Format: <title="Output Format"; items="Text","Bullet Points","Images","Graphs";> 
    {Determine the desired output format that your code will generate for presentation on the slides.}
  
    *Example Output:*
    This assistant provides a code snippet that generates a PowerPoint presentation with dynamic content based on the topics specified. The content and design of the slides reflect the subject matter interpreted through the chosen method of analysis, and are formatted according to the output preferences.
    No. of Parameters: 5
    Parameters: "Programming Language", "Topic Analysis Method", "Content Generation Technique", "Slide Design Template", "Output Format"
    
    *Input:*`;

  if (!item.command) {
    alert("Please fill in the 'Command' field first.");
    return;
  }
  const prompt = item.command + '\n' + generateDescriptionPrompt;
  return prompt;
};

export const generateTips = (item) => {
  let generateTipsPrompt= `Do not write intro
  Make sure to follow instructions
  Write only the answer:
  Add 5 to this number:`;

  if (!item.command) {
    alert("Please fill in the 'Command' field first.");
    return;
  }
  const prompt = item.command + '\n' + generateTipsPrompt;
  return prompt;
};

export const generateTags = (item) => {
  let generateTagsPrompt= `Do not write intro
  Make sure to follow instructions
  Write only the answer:
  Add 6 to this number:`;
  if (!item.command) {
    alert("Please fill in the 'Command' field first.");
    return;
  }
  const prompt = item.command + '\n' + generateTagsPrompt;
  return prompt;
};
