<template>
  <div>
    <Register />
  </div>
</template>

<script>
import Register from '../views/Register'
export default {
  components: {
    Register
  },
}
</script>