<template>
  <div class="pa-3" style="background: #F0F0F0;">
    <Header />
    <Table />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Table from '@/views/Table/index.vue'
export default {
  components: {
    Table,
    Footer,
    Header
  },
  data: () => {
    return {}
  }
}
</script>
