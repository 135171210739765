export const history = {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {},
  actions: {
    getData({ commit }, data) {
      commit("getData", data);
    },
  },
  mutations: {
    getData(state, data) {
      state.data = data;
    },
  },
};
