<template>
<!-- Last Update Jan 22-23, 2024 -->
<!-- Updated by: Jason Salcedo -->
<!-- Changes: Table Management, JSON Management, CSS Updates for variables, Overall Function Updates, Discounts, Price Recalculate, Delete Items, Edit Quantity, Printing, etc.-->
    <div class="sukiGroceryPlanner">
      <div class="header-pg d-flex align-start">
        <div style="width: 35%">
          <img src="@/assets/images/puregold.svg" style="width: 70%" alt="">
        </div>
        <div class="header-pg">
          <h2 class="pg">#PanaloShoppingMoments</h2>
          <h1 class="pg">Suki Grocery Planner</h1>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="aiForm">
              <div class="form-group">
                <h3 class="input-header" for="budget">Budget</h3>
                <input type="number" class="form-control" v-model="budget" name="Budget" placeholder="Enter your budget">
                <label class="guide-label">Define your spending limit for the grocery shopping period. Consider your overall financial plan and allocate funds accordingly.</label>
              </div>
              <div class="form-group">
                <h3 class="input-header" for="noOfPersons">No. of Persons</h3>
                <input type="number" class="form-control" v-model="noOfPersons" name="NoOfPersons" placeholder="Enter number of persons">
                <label class="guide-label">Select the number of individuals the grocery shopping needs to cater to. This information is crucial for quantity estimations.</label>
              </div>                     
              <div class="form-group">
                <h3 class="input-header" for="duration">Duration</h3>
                <select class="form-control" v-model="duration" name="Duration" @change="toggleOtherDuration(duration)">
                  <option>Weekly</option>
                  <option>Bi-weekly</option>
                  <option>Monthly</option>
                  <option>Others please specify</option>
                </select>
                <input type="number" class="form-control mt-2" v-if="otherDurationInput == true" v-model="otherDuration" name="Duration" placeholder="Specify number of days">
                <label class="guide-label">Choose the time frame for which the shopping list will be used. This helps in planning purchases and balancing perishables and non-perishables.</label>
              </div>
              <div class="form-group">
                  <h3 class="input-header" for="itemCategory">Item Category</h3>
                  <div class="checkbox-group">
                      <div v-for="category in availableCategories" :key="category" class="checkbox-item">
                          <input type="checkbox" :id="category" :value="category" v-model="selectedCategories" class="styled-checkbox">
                          <label :for="category">{{ category }}</label>
                      </div>
                  </div>
                  <div class="others-checkbox-item">
                      <input type="checkbox" id="others" value="Others" v-model="selectedCategories" @change="toggleOtherCategoryInput" class="styled-checkbox">
                      <label for="others">Others, please specify</label>
                      <input type="text" class="form-control mt-2" v-if="otherCategoryInput" v-model="otherCategory" placeholder="Specify other category">
                  </div>
                  <label class="guide-label">Select item categories to organize the shopping list and ensure all needs are met.</label>
              </div>
              <div class="form-group">
                <h3 class="input-header" for="brandPreferences">Brand Preferences</h3>
                <input type="text" class="form-control" v-model="brandPreferences" name="Brand Preferences" placeholder="Specify brand names (Example: Nestle, Personal Collection, Bonus, Unilever)">
                <label class="guide-label">Note specific brands you prefer for certain items. This can be motivated by dietary restrictions, quality standards, or price considerations.</label>
              </div>
              <div class="form-group">
                  <h3 class="input-header" for="listItems">Items</h3>
                  <textarea class="form-control" v-model="listItems" name="List Items" rows="3"></textarea>
                  <label class="guide-label">Detail the items to be purchased under each category. Be as specific as necessary to ensure your shopping list is comprehensive and tailored to your preferences.</label>
              </div>
              
              <!-- DISCOUNT -->
              <div class="form-group">
                  <h3 class="input-header" for="discount">Discount</h3>
                  <div class="discount-container">
                      <input type="number" class="form-control discount-input" v-model="discount" name="Discount" placeholder="Enter discount value">
                      <select class="form-control discount-type" v-model="discountType">
                          <option value="percent">Percent</option>
                          <option value="amount">Amount</option>
                      </select>
                  </div>
                  <label class="guide-label">Specify the discount to be applied. Select 'Percent' for percentage-based discounts or 'Amount' for fixed amount discounts.</label>
              </div>


              <div class="action-buttons-pg">
                  <button type="submit" @click="callAI">Create Shopping List</button>
                  <button type="button" class="btn btn-primary" @click="clearFields">Clear</button>
                  <button type="button" class="btn btn-primary" @click="printOutput">Print</button> 
                  <button type="button" class="btn btn-primary" @click="adjustShoppingListToBudget">Fit to Budget</button>
              </div>
              
              <div class="form-group">
                <h1 style="padding-top: 30px;">Shopping List</h1>
                <!-- Loading Indicator -->
                <div v-if="loadingOutput" class="loading-indicator">
                  Loading, please wait...
                </div>
                <!-- Error Message -->
                <div v-if="errorMessage" class="error-message">
                  {{ errorMessage }}
                </div>
                <table style="border-collapse: collapse; margin-left: auto; margin-right: auto; width: 100%;">
                  <thead style="background-color: #004a2f; color: #ffffff; border: 3px solid #004a2f;">
                    <tr>
                      <th class="qty-column">Qty.</th>
                      <th>Item</th>
                      <th>Unit Price (Php.)</th>
                      <th>Total Price (Php.)</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in shoppingList" :key="item.id" style="height: 35px;">
                      <td class="qty-column" style="text-align: center;">
                        <input type="number" v-model="item.Qty" @change="updateQuantity(index, item.Qty)" min="1">
                      </td>
                      <td><b>{{ item.Item }}</b></td>
                      <td style="text-align: center;">{{ item["Unit Price"].toFixed(2) }}</td>
                      <td style="text-align: center;">{{ item["Total Price"].toFixed(2) }}</td>
                      <td style="text-align: center; cursor: pointer;" @click="deleteItem(index)">❌</td>
                    </tr>
                  </tbody>
                  <tfoot style="margin-bottom: 50px;">
                    <tr>
                      <td colspan="3" style="text-align: right;"><strong>Total Amount:</strong></td>
                      <td colspan="2" style="text-align: center;"><strong>{{ totalAmount.toFixed(2) }}</strong></td>
                    </tr>
                    <tr>
                      <td colspan="3" style="text-align: right;"><strong>Discount:</strong></td>
                      <td colspan="2" style="text-align: center;">{{ formatDiscount(discountValue) }}</td>
                    </tr>
                    <tr style="background-color: #E2E400;">
                      <td colspan="3" style="text-align: right;"><strong>Net Amount:</strong></td>
                      <td colspan="2" style="text-align: center;"><strong>{{ netAmount.toFixed(2) }}</strong></td>
                    </tr>
                  </tfoot>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-content">
          <h4>Made with <span>❤️</span> by:</h4>
          <h3>Magus Technology Inc.</h3>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  
    export default {
      data: () => {
        return {
          loadingOutput: false,
          otherDurationInput: false,
          appConfig: {
              secretKey: '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',
              modelSelection: 'gpt-4-1106-preview',
              budgetPrompt: ''
          },
          budget: '',
          noOfPersons: '',
          duration: 'Weekly',
          itemCategory: '',
          availableCategories: ['Food', 'Beverage', 'Cleaning Items', 'Personal Care', 'Dairy', 'Produce', 'Snacks', 'Bread and Pastries', 'Frozen Foods', 'Meat & Seafood', 'Candies and Chocolates'],
          selectedCategories: [],
          otherCategoryInput: false,
          otherCategory: '',
          brandPreferences: '',
          otherDuration: '',
          listItems: '',
          shoppingList: [],
          discount: 0,
          discountType: 'percent', // Default to 'percent'
          totalAmount: 0,
          discountValue: 0,
          netAmount: 0,
          errorMessage: '',
          commandPrompt: `
              **Main Instructions:**
  - Create a grocery shopping list with the following considerations:
    - **Budget:** Adhere closely to the specified budget, ensuring the total cost of items falls within the budget range.
    - **Duration:** Plan for the specified duration.
    - **Item Categories:** Include various categories of items.
    - **Brand Preferences:** Select items based on the given brand preferences.
    - **Specific Items:** Ensure all listed items are included.
  
  **Additional Instructions:**
  - **Omit Feedback and Conclusion Statements:** 
    - Do not include any feedback or conclusion statements in the output. Focus solely on presenting the grocery shopping list code right away without additional commentary.
  - **Output Format:** 
    - Present the each item in the shopping list only in pure JSON format. Do not enclose items in <ul>, <li>, <div>, or any other HTML codes.
    - Do not enclose the output in "'''json" and in markdow format and something like that.
  
  {
      "item1": {
          "Qty": 10,
          "Item": "Example Item 1",
          "Unit Price": 5.99
      },
      "item2": {
          "Qty": 5,
          "Item": "Example Item 2",
          "Unit Price": 8.49
      }
  }
  
  - **Avoid Special Characters Enclosing Output:**
   - Present the shopping list directly as HTML code without enclosing it in code box markdown format. 
   - Do not use special characters to encapsulate the HTML code. The output should be the raw HTML code.
   - Do not ever ever ever place or use a backtick symbol.
  - **Item and Price Details:**
    - **Branded Products:** Use branded names for items as indicated in the brand preferences.
    - **Local Brands:** Base brand selections on brands commonly found in the Philippines.
    - **Product Producer Brands:** 
     -- If specified as Pure Gold or PureGold, search products here: https://puregold.com.ph/pgcatalog/category/subcategory
     -- If specified as Personal Collection Products, Personal Collection, or PC, search products here: https://www.personalcollection.com.ph/great-products-for-direct-selling/
     -- If product brands seem vague, please conduct a search and navigate their website to learn about their products.
    - **Specificity in Items:** For generic items like "Bread, Water, Shampoo, Soap," provide a specific brand for each based on the brand preferences or instruction references.
    - **Pricing:** 
    - **Quantity (Qty.):** Indicate the quantity for each item.
    - **Unit Price:** 
     -- Provide the individual price of each item and indicate accurate price amounts based on your training data and current research.
     -- Prices are computed in Philippine Peso but do not write (Php.). 
     -- Write the price with 2 decimal places (Example: .00).
  
  **Total Budget Calculation Logic:** 
  - **Total Amount Alignment with Budget:** 
    - The total amount of the products listed should closely align with the provided budget, ensuring efficient spending.
    - **Example:** If the budget is 1500 Php, the total amount of products should tally around 1300-1500 Php.
    - This range represents approximately 87% to 100% of the allocated budget.
  - **Budget Utilization:**
    - Aim to utilize at least 87% of the budget, ensuring that the essential items are covered without significant underspending.
    - Avoid exceeding 100% of the budget to maintain financial discipline.
    - If the calculated total amount exceeds the budget, prioritize essential items and adjust quantities to bring the total within the specified budget range.
    - Consider reducing quantities or excluding non-essential items to align with the budget without compromising crucial purchases.
  - **Calculation of Total Price:**
    - The total price is calculated by summing the 'Total Price' column in the shopping list.
    - Ensure the final sum is within the specified budget range.
    - Ensure the final sum is not higher or greater than the specified budget in the input.
  - **JSON Output
      - Do not output the Total Price, Total Amount, Final Amount, Sum of Price of Products, in the JSON Format
      
  **Other Considerations:**
  - **Quantity Guidelines:**
    - Consider the household size; assume a standard composition if not specified. Example: For 7 people, assume 2 parents and 5 individuals with specific birth orders with balanced male to female ratio and varying ages composing of kids to teens and adult.
    - For personal items (e.g., toothbrushes, deodorant) Align item quantities with the total household members. Maintain consistency in quantities to match the household size.
  - **Food Planning:**
    - Factor in 3-4 meals daily with balanced nutrition for carbohydrates, protein, and vegetables. Snacks are optional but not mandatory.
    - Tailor the food list based on household size and meal frequency.
  - **Cleaning and other household items:**
    - Examples are: Big dishwashing liquid, detergent, disinfectant, insecticides, etc.
    - Typically, one item per type is sufficient for the entire family and should last for at least 1 week.
    - Adjust quantities based on use frequency and specific household needs.
  - **Additional Logic:**
    - Consider unique household factors for adjusting quantities and choices.
    - Ensure a holistic approach to the shopping list based on household dynamics.
  
  Product list below displayed in this format: (Product Name, Price in Php)
  ** Example Pure Gold Products**
  - **Dairy**
  SUNNY FARMS SALTED BUTTER 225G, 71.05
  QUEENSLAND TIN BUTTER 175G, 178.80
  MAGNOLIA GOLD BUTTER LITE 200G, 139.40
  
  ==============
  INPUT:
              
  `,
        }
      },
      created() {
        document.title = "Puregold Budget Planner"
      },
      computed: {
      },
      watch: {
        budget(newValue, oldValue) {
          this.adjustShoppingListToBudget();
        }
      },
      mounted() {
      },
      methods: {
        toggleOtherDuration(selectBox){
          if (selectBox === 'Others please specify') {
            this.otherDurationInput = true;
          } else {
            this.otherDurationInput = false;
            this.otherDuration = ''; // Clear the input when other options are selected
          }
        },
        updateBudgetPrompt() {
          let prompt = this.commandPrompt;
          if (this.budget) prompt += `Budget: ${this.budget}\n`;
          if (this.noOfPersons) prompt += `No. of Persons: ${this.noOfPersons}\n`;
          if (this.otherDurationInput == true) {
            if (this.duration) prompt += `Duration: ${this.otherDuration} Days\n`;
          } else {
            if (this.duration) prompt += `Duration: ${this.duration}\n`;
          }
          if (this.selectedCategories.length > 0) {
            let categories = this.selectedCategories.join(', ');
            if (this.otherCategory) {
              categories += `, ${this.otherCategory}`;
            }
            prompt += `Item Category: ${categories}\n`;
          }
          if (this.brandPreferences) prompt += `Brand Preferences: ${this.brandPreferences}\n`;
          if (this.listItems) prompt += `Items: ${this.listItems}\n`;
          this.appConfig.budgetPrompt = prompt;
          
          //** console.log("Updated Budget Prompt:", prompt);
        },

        clearFields() {
            this.budget = '';
            this.noOfPersons = '';
            this.duration = 'Weekly';
            this.otherDuration = '';
            this.selectedCategories = []; // Clear selected categories
            this.otherCategoryInput = false; // Reset the visibility of the 'other' input field
            this.otherCategory = ''; // Clear the 'other' input field
            this.brandPreferences = '';
            this.listItems = '';
            this.output = '';
            this.discount = 0; // Reset the discount value
            this.discountType = 'percent'; // Reset the discount type to default
        },

        printOutput() {
          let printWindow = window.open('', '_blank');

          // Start the HTML document
          printWindow.document.write('<html><head><title>Print Shopping List</title>');
          // Add any additional styles here for printing
          printWindow.document.write('<style> body { font-family: Arial, sans-serif; } table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; } th { background-color: #f2f2f2; }</style>');
          printWindow.document.write('</head><body>');

          // Title for the print page
          printWindow.document.write('<h1>Shopping List</h1>');

          // Start the table
          printWindow.document.write('<table>');
          printWindow.document.write('<thead><tr><th>Qty.</th><th>Item</th><th>Unit Price</th><th>Total Price</th></tr></thead>');
          printWindow.document.write('<tbody>');

          // Loop through each item in the shopping list and create table rows
          this.shoppingList.forEach(item => {
            printWindow.document.write(`<tr><td>${item.Qty}</td><td>${item.Item}</td><td>${item["Unit Price"].toFixed(2)}</td><td>${item["Total Price"].toFixed(2)}</td></tr>`);
          });

          // Close the table body and add the footer for totals
          printWindow.document.write('</tbody>');
          printWindow.document.write('<tfoot>');
          printWindow.document.write(`<tr><td colspan="3" style="text-align: right;"><strong>Total Amount:</strong></td><td>${this.totalAmount.toFixed(2)}</td></tr>`);
          printWindow.document.write(`<tr><td colspan="3" style="text-align: right;"><strong>Discount:</strong></td><td>${this.formatDiscount(this.discountValue)}</td></tr>`);
          printWindow.document.write(`<tr><td colspan="3" style="text-align: right;"><strong>Net Amount:</strong></td><td>${this.netAmount.toFixed(2)}</td></tr>`);
          printWindow.document.write('</tfoot>');
          printWindow.document.write('</table>');

          // Close the HTML document
          printWindow.document.write('</body></html>');
          printWindow.document.close();

          // Invoke the print function
          printWindow.print();
        },

        convertJsonToTable(jsonInput) {
          // Call the method to remove backticks
          jsonInput = this.removeBackticks(jsonInput);
          //** console.log('Received JSON Input:', jsonInput);
          try {
            if (!jsonInput || jsonInput.trim() === '') {
              //** console.log('Empty or invalid JSON input, skipping table generation.');
              return;
            }

            let jsonData = JSON.parse(jsonInput);

            this.shoppingList = Object.keys(jsonData).map(key => {
              let quantity = this.convertFractionToDecimal(jsonData[key].Qty);
              if (isNaN(quantity)) {
                console.error(`Invalid quantity for item ${key}`);
                throw new Error(`Invalid quantity for item ${key}`);
              }
              return {
                id: key,
                Qty: quantity,
                Item: jsonData[key].Item,
                "Unit Price": jsonData[key]["Unit Price"],
                "Total Price": quantity * jsonData[key]["Unit Price"]
              };
            });

            //** console.log('Converted shopping list:', this.shoppingList);
            this.generateTable();
          } catch (error) {
            console.error('Error processing JSON:', error);
            this.errorMessage = 'Error processing JSON. Please check the input format.';
          }
        },

        removeBackticks(jsonInput) {
            // Replace all occurrences of backticks with an empty string
            jsonInput = jsonInput.replace(/```json/g, '');
            jsonInput = jsonInput.replace(/```/g, '');
            return jsonInput;
        },

        fixJsonFormatting(jsonString) {
          try {
            // Attempt to parse the JSON string first to see if it's valid
            JSON.parse(jsonString);
            //** console.log('JSON is valid, no fixing needed.');
            return jsonString;
          } catch (error) {
            //** console.log('Attempting to fix JSON formatting...');
            // Regular expression to find key-value pairs
            const regex = /(\w+): ?(["\w.]+)/g;
            // Replace incorrect formatting with corrected JSON string format
            const fixedJsonString = jsonString.replace(regex, '"$1": "$2"');

            try {
              // Validate the fixed JSON
              JSON.parse(fixedJsonString);
              //** console.log('JSON fixed successfully.');
              return fixedJsonString;
            } catch (error) {
              console.error('Unable to fix JSON formatting automatically:', error);
              // Returning original string since automatic fixing failed
              return jsonString;
            }
          }
        },

        convertFractionToDecimal(fraction) {
          // Check if the fraction is actually a string and contains a '/'
          if (typeof fraction === 'string' && fraction.includes('/')) {
            const [numerator, denominator] = fraction.split('/').map(Number);
            if (!isNaN(numerator) && !isNaN(denominator) && denominator !== 0) {
              return numerator / denominator;
            } else {
              return NaN; 
            }
          } else {
            return Number(fraction); 
          }
        },

        deleteItem(index) {
            //** console.log('Deleting item at index:', index);
            this.shoppingList.splice(index, 1);
            this.recalculateTotals(); // Recalculate total amount after deleting an item
        },

        recalculateTotals() {
          let totalAmount = this.shoppingList.reduce((sum, item) => sum + item["Total Price"], 0);
          let discountValue = this.calculateDiscount(totalAmount);
          let netAmount = totalAmount - discountValue;

          this.totalAmount = totalAmount;
          this.discountValue = discountValue;
          this.netAmount = netAmount;

          //** console.log(`Recalculated Values - Total Amount: ${totalAmount.toFixed(2)}, Discount Value: ${discountValue.toFixed(2)}, Net Amount: ${netAmount.toFixed(2)}`);
        },

        generateTable() {
          //** console.log('Generating the shopping list table');
          let totalAmount = 0;
          this.shoppingList = this.shoppingList.map(item => {
            const totalPrice = item.Qty * item["Unit Price"];
            totalAmount += totalPrice;
            //** console.log(`Item: ${item.Item}, Qty: ${item.Qty}, Unit Price: ${item["Unit Price"]}, Total Price: ${totalPrice}`);

            return { ...item, "Total Price": totalPrice };
          });
          const discountValue = this.calculateDiscount(totalAmount);
          const netAmount = totalAmount - discountValue;
          //** console.log(`Total Amount: ${totalAmount.toFixed(2)}, Discount Value: ${discountValue.toFixed(2)}, Net Amount: ${netAmount.toFixed(2)}`);
          this.totalAmount = totalAmount;
          this.discountValue = discountValue;
          this.netAmount = netAmount;
        },

        validateInputs() {
          if (!this.budget || this.budget <= 0) {
            alert('Please enter a valid budget.');
            return false;
          }
          if (!this.noOfPersons || this.noOfPersons <= 0) {
            alert('Please enter the number of persons.');
            return false;
          }
          if (this.selectedCategories.length === 0) {
            alert('Please select at least one item category.');
            return false;
          }
          if (this.selectedCategories.includes('Others') && !this.otherCategory.trim()) {
            alert('Please specify the other category.');
            return false;
          }
          return true;
        },
        
        callAI() {
          if (!this.validateInputs()) {
            alert('Please fill in all required fields correctly.');
            return;
          }

          this.updateBudgetPrompt(); // Update the budgetPrompt with the formatted string

          if (!this.appConfig.secretKey) {
            alert("Please enter the OpenAI secret key.");
            return;
          }

          /*
          console.log('Form Data:', {
            budget: this.budget,
            noOfPersons: this.noOfPersons,
            duration: this.duration,
            otherDuration: this.otherDuration,
            selectedCategories: this.selectedCategories,
            otherCategory: this.otherCategory,
            brandPreferences: this.brandPreferences,
            listItems: this.listItems
          });
          console.log('Budget Prompt:', this.appConfig.budgetPrompt);
          */

          this.loadingOutput = true;
          this.errorMessage = ''; // Reset error message before making the API call

          const apiUrl = "https://api.openai.com/v1/chat/completions";
          fetch(apiUrl, {
            method: 'POST',
            headers: {
              "Authorization": "Bearer " + this.appConfig.secretKey,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              "model": this.appConfig.modelSelection,
              "messages": [
                {"role": "system", "content": "You are a helpful assistant."},
                {"role": "user", "content": this.appConfig.budgetPrompt}
              ],
              "max_tokens": 1400
            })
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok: ' + response.statusText);
            }
            return response.json();
          })
          .then(data => {
            const outputText = data.choices[0].message.content.trim();
            //** console.log('API Text Output:', outputText);
            this.convertJsonToTable(outputText);
          })
          .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
            this.errorMessage = "Error: " + error.message;
          })
          .finally(() => {
            this.loadingOutput = false; // Set loadingOutput to false in the finally block
          });
        },

        toggleOtherCategoryInput() {
            this.otherCategoryInput = this.selectedCategories.includes('Others');
            if (!this.otherCategoryInput) {
                this.otherCategory = ''; // Clear the 'other' input field when 'Others' is unchecked
            }
        },

        calculateDiscount(totalAmount) {
          let discountValue = 0;

          if (this.discountType === 'percent') {
            if (typeof totalAmount === 'number' && typeof this.discount === 'number') {
              discountValue = (totalAmount * this.discount) / 100;
            }
          } else {
            if (typeof this.discount === 'number') {
              discountValue = this.discount;
            }
          }

          //** console.log(`Discount of Total Amount (${totalAmount}): ${discountValue}`);
          return discountValue;
        },


        formatDiscount(discountValue) {
            let formattedDiscount;

            if (this.discountType === 'percent') {
                formattedDiscount = this.discount + '% (' + discountValue.toFixed(2) + ')';
            } else {
                formattedDiscount = '₱' + discountValue.toFixed(2);
            }

            //** console.log(`Formatted Discount: ${formattedDiscount}`);
            return formattedDiscount;
        },

        updateQuantity(itemIndex, newQuantity) {
          const item = this.shoppingList[itemIndex];
          item.Qty = newQuantity;
          item["Total Price"] = item.Qty * item["Unit Price"];
          this.recalculateTotals();
          //this.adjustShoppingListToBudget();
        },

        adjustShoppingListToBudget() {
            let totalAmount = this.totalAmount;
            let budget = parseFloat(this.budget);

            if (!budget || isNaN(budget)) {
                //** console.log("Invalid or undefined budget.");
                return;
            }

            // Sort items by unit price for efficient adjustment
            this.shoppingList.sort((a, b) => a["Unit Price"] - b["Unit Price"]);

            let adjustments = 0; // Counter to limit adjustments
            const maxAdjustments = this.shoppingList.length * 2; // Set a reasonable limit

            // Adjust the shopping list to match the budget
            while ((totalAmount !== budget) && adjustments < maxAdjustments) {
                let updated = false;

                if (totalAmount > budget) {
                    // Reduce quantities if total amount exceeds the budget
                    for (let item of this.shoppingList) {
                        if (item.Qty > 1) {
                            //** console.log(`Reducing quantity of ${item.Item} from ${item.Qty} to ${item.Qty - 1}`);
                            item.Qty--;
                            totalAmount -= item["Unit Price"];
                            updated = true;
                            break;
                        }
                    }
                } else {
                    // Increase quantities if total amount is under the budget
                    for (let item of this.shoppingList) {
                        if (totalAmount + item["Unit Price"] <= budget) {
                            //** console.log(`Increasing quantity of ${item.Item} from ${item.Qty} to ${item.Qty + 1}`);
                            item.Qty++;
                            totalAmount += item["Unit Price"];
                            updated = true;
                            break;
                        }
                    }
                }

                if (!updated) {
                    //** console.log("No further adjustments possible within budget constraints.");
                    break;
                }

                adjustments++;
            }

            this.totalAmount = totalAmount;
            this.discountValue = this.calculateDiscount(totalAmount);
            this.netAmount = totalAmount - this.discountValue;

            //** console.log("Shopping list adjusted to utilize the budget effectively.");
        },


      },
    }
  </script>
  
  <style scoped>
  .sukiGroceryPlanner {
    --brand-primary-color: #004a2f;
    --brand-secondary-color: #E2E400;
    --brand-background-color: #ffffff;
    --brand-error-color: red;
    --brand-text-color: #7c7c7c;
    --brand-border-color: #ced4da;
    --brand-hover-bg-color: #004a2f;
    --brand-hover-text-color: #E2E400;
    --brand-placeholder-color: #ffffff;

    background-color: var(--brand-primary-color);
    font-family: "Montserrat-Bold";
  }

  .aiForm{
    background-color: var(--brand-background-color);
    border: 1px solid var(--brand-border-color);
    height: auto !important;
    line-height: 100%;
    border-radius: 20px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 50px;
  }

  .loading-indicator {
    color: var(--brand-primary-color);
  }

  .error-message {
    color: var(--brand-error-color);
  }

  .header-pg {
      align-items: center;
      background-color: var(--brand-secondary-color);
      padding-top: 10px;
      padding-bottom: 10px;
  }

  span {
      color: var(--brand-primary-color);
      text-align: center !important;
      font-size: 30px !important;
  }
  
  .pg, h2 .pg {
    color: #004a2f !important;
    text-align: center !important;
  }

  h1 .pg {
      font-size: 100px !important;
      text-align: center;
      font-weight: bolder;
  }

  .logo {
      max-width: 20%;
      height: auto;
      position: absolute;
      left: 10px; /* Adjust the left position as needed */
      
  }

  h3 .pg{
      font-weight: bold;
  }

  .footer {
      background-color: var(--brand-background-color);
      padding: 10px;
      width: 100% !important;
      text-align: center;
      position: absolute;
      padding-top: 30px;
      padding-bottom: 30px;

  }

  .input, .guide-label {
    font-family: "Montserrat-Bold" !important;
  }

  .input-header {
      color: #004a2f !important;
      margin-bottom: 0.5rem !important; 
      font-size: 20px !important;
  }

  form {
      margin-top: 20px; 
      padding: 20px;
      height: auto !important;
  }

  .form-control {
      width: 100% !important;
      margin-bottom: 15px !important; /* Add bottom margin to form controls for spacing */
      background-color: var(--brand-secondary-color) !important;
      color: var(--brand-primary-color) !important;
      border: 3px solid var(--brand-primary-color) !important;
      border-width: 3px !important;
      border-radius: 15px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      height: auto !important;
  }

  .form-control:focus {
     background-color: var(--brand-background-color);
  }

  .form-control ::placeholder {
      color: var(--brand-placeholder-color) !important;
  }

  .placeholder {
    color: var(--brand-placeholder-color) !important;
  }

  .action-buttons-pg {
      display: flex;
      justify-content: center;
      margin-top: 20px;
  }

  .action-buttons-pg button {
      margin: 0 10px;
      border: 3px solid var(--brand-primary-color);
      border-radius: 15px !important;
      width: 200px; 
      background-color: var(--brand-secondary-color);
      color: var(--brand-primary-color);
      padding-top: 10px;
      padding-bottom: 10px;
  }

  .action-buttons-pg button:hover {
      border: 3px solid var(--brand-primary-color)!important;
      background-color: var(--brand-primary-color) !important;
      color: #E2E400;
  }

  .form-group {
      background-color: #ffffff;
  }

  .guide-label {
      font-family: "Montserrat-Bold" !important;
      color: #7c7c7c !important; 
      margin-bottom: 1.5rem !important;
      font-size: 17px;
  }

  #output {
      margin-top: 50px; 
      background-color: #ffffff; 
      border: 3px solid var(--brand-primary-color) !important;
      border-radius: 20px !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding-top: 40px;
      padding-bottom: 40px;
      min-height: 600px;
  }

  #loadingOutput {
      color: var(--brand-primary-color);
  }

  #outputContent {
      margin-top: 10px; 
  }

  .checkbox-group {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
  }

  .checkbox-item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 10px; 
      width: calc(25% - 20px); 
  }

  .styled-checkbox {
      opacity: 0;
      position: absolute;
  }
  /* Style for custom checkbox appearance */
  .styled-checkbox + label:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border: 2px solid var(--brand-primary-color);
      border-radius: 3px;
      background-color: white;
  }

  .styled-checkbox + label {
      position: relative;
      padding-left: 25px; 
  }

  /* Green background for checked state */
  .styled-checkbox:checked + label:before {
      background-color: var(--brand-primary-color);
      border-color: var(--brand-primary-color);
  }

  .others-checkbox-item {
      display: block; 
      margin-top: 10px; 
      margin-bottom: 20px;
  }

  .discount-container {
      display: flex;
      align-items: center;
      gap: 10px; 
  }

  .discount-input, .discount-type {
      flex-grow: 2; 
  }

  .qty-column {
    width: 50px;
  }

</style>