<template>
  <div>
    <v-row class="mx-3" style="margin-bottom: -20px">
      <v-col cols="12">
        <div style="overflow: scroll;">
          <div class="d-flex align-center mb-3">
              <template>
                <v-btn class="mr-3 add-btn" color="#1976d2 !important">
                  <v-icon size="30" v-if="isBoxedView == true" @click="isBoxedView=!isBoxedView">mdi-view-dashboard</v-icon>
                  <v-icon size="30" v-else @click="isBoxedView=!isBoxedView" >mdi-table</v-icon>
                </v-btn>
                <v-btn
                  color="#1976d2 !important" 
                  class="mr-3 add-btn"
                  @click="getAction([], 'add')"
                  v-if="add"
                  >Create</v-btn
                >
                <v-btn
                  color="red"
                  v-if="showDeleteAll"
                  class="mr-3 delete-all-btn"
                  @click="getAction(selected, 'deleteall')"
                  >Delete All</v-btn
                >
                <div class="mr-3" v-else style="width: 128px; background: red"></div
                >
              </template>
              <input class="search-text" v-model="search" placeholder="Search" />
              <v-file-input ref="fileInput" placeholder="Import File" class="mx-3" style="width: 300px;" accept=".csv, .xlsx, .xls"></v-file-input>
              <v-btn @click="importFile" class="mr-3 add-btn" color="#1976d2 !important">
                <v-icon size="30">mdi-upload</v-icon>
              </v-btn>
              <v-btn
                color="#1976d2 !important" 
                class="mr-3 add-btn"
                @click="exportToCSV"
                > CSV</v-btn
              >
              <v-btn
                color="#1976d2 !important" 
                class="mr-3 add-btn"
                @click="exportToExcel"
                >XLSX</v-btn
              >
              <v-btn
                color="#1976d2 !important" 
                class="mr-3 add-btn"
                @click="exportToPDF"
                >PDF</v-btn
              >
            </div>
        </div>
      </v-col">
    </v-row>
    <v-row class="mx-3 my-0">
      <v-col cols="12">
        <div class="card-div" >
          <template>
            <div class="align-center mt-2 mx-2">
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideID()"
                  v-if="hide_id == true"
                  >Hide ID</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideID()"
                  v-else
                  >Show ID</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideMagusAI()"
                  v-if="hide_MagusAI == true"
                  >Hide MagusAI ID</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideMagusAI()"
                  v-else
                  >Show MagusAI ID</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideName()"
                  v-if="hide_name == true"
                  >Hide Name</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideName()"
                  v-else
                  >Show Name</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideMain()"
                  v-if="hide_main == true"
                  >Hide Main Category</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideMain()"
                  v-else
                  >Show Main Category</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideSub()"
                  v-if="hide_sub == true"
                  >Hide Sub Category</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideSub()"
                  v-else
                  >Show Sub Category</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideType()"
                  v-if="hide_type == true"
                  >Hide Type</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideType()"
                  v-else
                  >Show Type</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideTask()"
                  v-if="hide_task == true"
                  >Hide Task</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideTask()"
                  v-else
                  >Show Task</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideDescription()"
                  v-if="hide_description == true"
                  >Hide Description</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideDescription()"
                  v-else
                  >Show Description</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideCommand()"
                  v-if="hide_command == true"
                  >Hide Command</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideCommand()"
                  v-else
                  >Show Command</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideTips()"
                  v-if="hide_tips == true"
                  >Hide Tips</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideTips()"
                  v-else
                  >Show Tips</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideTags()"
                  v-if="hide_tags == true"
                  >Hide Tags</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideTags()"
                  v-else
                  >Show Tags</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideAuthor()"
                  v-if="hide_author== true"
                  >Hide Author</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideAuthor()"
                  v-else
                  >Show Author</v-btn
                >
              </template>
              <template>
                <v-btn
                  small
                  color="#1976d2 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideAction()"
                  v-if="hide_action == true"
                  >Hide Actions</v-btn
                >
                 <v-btn
                  small
                  color="#424242 !important"
                  class="mr-3 mb-2 add-btn"
                  @click="hideAction()"
                  v-else
                  >Show Actions</v-btn
                >
              </template>
            </div>
          </template>
        </div>
      </v-col>
      <v-col cols="12" style="margin-top: 0px;">
          <template>            
            <div class="card-div" v-if="!isBoxedView">
              <v-data-table
                show-select
                v-model="selected"
                :headers="headers"
                :item-key="itemkey"
                :items="items"
                :search="search"
                :loading="loading"
                :items-per-page="limit"
                mobile-breakpoint="0"
                :show-expand="showexpand"
                :single-expand="true"
                hide-default-footer

              >
                <template v-slot:item.update_delete="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'open')"
                      >
                        mdi-open-in-new
                      </v-icon>
                    </template>
                    <span>Open Command</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'update')"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: red"
                        @click="getAction(item, 'delete')"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.name="{ item }">
                  <div v-if="item.name == null">
                    <span>{{item.name}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.name.length < 100">{{item.name}}</span>
                    <div v-else>
                      <span v-if="!readMorename[item.id]">{{item.name.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMorename[item.id]">{{item.name}}<br></span>

                      <button class="see" @click="showMoreName(item.id)" v-if="!readMorename[item.id]">Show more</button>
                      <button class="see" @click="showLessName(item.id)" v-if="readMorename[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.main_category="{ item }">
                  <div v-if="item.main_category == null">
                    <span>{{item.main_category}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.main_category.length < 100">{{item.main_category}}</span>
                    <div v-else>
                      <span v-if="!readMoremain_category[item.id]">{{item.main_category.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMoremain_category[item.id]">{{item.main_category}}<br></span>

                      <button class="see" @click="showMoreMain_category(item.id)" v-if="!readMoremain_category[item.id]">Show more</button>
                      <button class="see" @click="showLessMain_category(item.id)" v-if="readMoremain_category[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.sub_category="{ item }">
                  <div v-if="item.sub_category == null">
                    <span>{{item.sub_category}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.sub_category.length < 100">{{item.sub_category}}</span>
                    <div v-else>
                      <span v-if="!readMoresub_category[item.id]">{{item.sub_category.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMoresub_category[item.id]">{{item.sub_category}}<br></span>

                      <button class="see" @click="showMoreSub_category(item.id)" v-if="!readMoresub_category[item.id]">Show more</button>
                      <button class="see" @click="showLessSub_category(item.id)" v-if="readMoresub_category[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.type="{ item }">
                  <div v-if="item.type == null">
                    <span>{{item.type}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.type.length < 100">{{item.type}}</span>
                    <div v-else>
                      <span v-if="!readMoretype[item.id]">{{item.type.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMoretype[item.id]">{{item.type}}<br></span>

                      <button class="see" @click="showMoreType(item.id)" v-if="!readMoretype[item.id]">Show more</button>
                      <button class="see" @click="showLessType(item.id)" v-if="readMoretype[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.task="{ item }">
                  <div v-if="item.task == null">
                    <span>{{item.task}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.task.length < 100">{{item.task}}</span>
                    <div v-else>
                      <span v-if="!readMoretask[item.id]">{{item.task.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMoretask[item.id]">{{item.task}}<br></span>

                      <button class="see" @click="showMoreTask(item.id)" v-if="!readMoretask[item.id]">Show more</button>
                      <button class="see" @click="showLessTask(item.id)" v-if="readMoretask[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.description="{ item }">
                  <div v-if="item.description == null">
                    <span>{{item.description}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.description.length < 100">{{item.description}}</span>
                    <div v-else>
                      <span v-if="!readMoredescription[item.id]">{{item.description.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMoredescription[item.id]">{{item.description}}<br></span>

                      <button class="see" @click="showMoreDescription(item.id)" v-if="!readMoredescription[item.id]">Show more</button>
                      <button class="see" @click="showLessDescription(item.id)" v-if="readMoredescription[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.command="{ item }">
                  <div v-if="item.command == null">
                    <span>{{item.command}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.command.length < 100">{{item.command}}</span>
                    <div v-else>
                      <span v-if="!readMorecommand[item.id]">{{item.command.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMorecommand[item.id]">{{item.command}}<br></span>

                      <button class="see" @click="showMoreCommand(item.id)" v-if="!readMorecommand[item.id]">Show more</button>
                      <button class="see" @click="showLessCommand(item.id)" v-if="readMorecommand[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.tips="{ item }">
                  <div v-if="item.tips == null">
                    <span>{{item.tips}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.tips.length < 100">{{item.tips}}</span>
                    <div v-else>
                      <span v-if="!readMoretips[item.id]">{{item.tips.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMoretips[item.id]">{{item.tips}}<br></span>

                      <button class="see" @click="showMoreTips(item.id)" v-if="!readMoretips[item.id]">Show more</button>
                      <button class="see" @click="showLessTips(item.id)" v-if="readMoretips[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

                <template v-slot:item.tags="{ item }">
                  <div v-if="item.tags == null">
                    <span>{{item.tags}}</span>
                  </div>
                  <div v-else>
                    <span v-if="item.tags.length < 100">{{item.tags}}</span>
                    <div v-else>
                      <span v-if="!readMoretags[item.id]">{{item.tags.substring(0, 100) + ".."}}<br></span>
                      <span v-if="readMoretags[item.id]">{{item.tags}}<br></span>

                      <button class="see" @click="showMoreTags(item.id)" v-if="!readMoretags[item.id]">Show more</button>
                      <button class="see" @click="showLessTags(item.id)" v-if="readMoretags[item.id]">Show less</button>
                    </div>
                  </div>
                </template>

              </v-data-table>
              <div class="d-flex justify-end align-center mt-5">
                <v-select
                  solo
                  dense
                  hide-details
                  v-model="limit"
                  :items="pageLimits"
                  item-text="text"
                  item-value="value"
                  style="max-width: 85px; text-align: center"
                  :disabled="total == 0"
                />
                <v-pagination
                  v-model="page"
                  :length="total"
                  :total-visible="6"
                ></v-pagination>
              </div>
            </div>
            <v-row v-else class="box-grid">
              <div class="text-center pa-5" style="width: 100%;" v-if="items.length == 0">
                <span style="color: lightgray;">No Data Available</span>
              </div>  
              <v-col v-else v-for="item in items" :key="item.id" cols="12" sm="6" md="3">
                <div class="box">
                  <div class="image-placeholder" @click="openCommand(item)">
                  </div>
                  <div class="box-content">
                    <div v-if="item.name == null">
                      <span>{{item.name}}</span>
                    </div>
                    <div v-else>
                      <span v-if="item.name.length < 100">{{item.name}}</span>
                      <div v-else>
                        <span v-if="!readMorename[item.id]">{{item.name.substring(0, 100) + ".."}}<br></span>
                        <span v-if="readMorename[item.id]">{{item.name}}<br></span>

                        <button class="see" @click="showMoreName(item.id)" v-if="!readMorename[item.id]">Show more</button>
                        <button class="see" @click="showLessName(item.id)" v-if="readMorename[item.id]">Show less</button>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <div class="d-flex justify-end align-center mt-5">
                <v-select
                  solo
                  dense
                  hide-details
                  v-model="limit"
                  :items="pageLimits"
                  item-text="text"
                  item-value="value"
                  style="max-width: 85px; text-align: center"
                  :disabled="total == 0"
                />
                <v-pagination
                  v-model="page"
                  :length="total"
                  :total-visible="6"
                ></v-pagination>
              </div>
            </v-row>
          </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import Select from "../Select.vue";
import { pageLimits } from "@/utils/data";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { getCommands, upload, updateCommands, deleteCommands, addCommands } from '@/api/command';

export default {
  components: {
    Select,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    select: {
      type: Array,
      default: () => {
        return [];
      },
    },
    itemkey: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    showexpand: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    expandtype: {
      type: String,
      default: () => {
        return "";
      },
    },
    add: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    delete: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    total: {
      type: Number,
    },
    addItem: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      pageLimits,
      addCommands,
      search: "",

      isBoxedView: false,  

      selected: [],

      hide_id: true,
      hide_MagusAI: true,
      hide_name: true,
      hide_main: true,
      hide_sub: true,
      hide_type: true,
      hide_description: true,
      hide_command: true,
      hide_tips: true,
      hide_tags: true,
      hide_action: true,
      hide_task: true,
      hide_author: true,

      track: null,

      count: 1,
      page: 1,
      limit: 10,

      data: {},

      visible: null,
      sort: null,
      mood: null,
      category: null,
      audiotype: [],
      subscription: [],

      readMorename: {},
      readMoremain_category: {},
      readMoresub_category: {},
      readMoretype: {},
      readMoretask: {},
      readMoredescription: {},
      readMorecommand: {},
      readMoretips: {},
      readMoretags: {},
    };
  },
  watch: {
    select(value) {
      this.selected = value;
    },
    limit() {
      this.updateLimiPage();
    },
    page() {
      this.updateLimiPage();
    },
    search() {
      this.updateSearch();
    },
  },
  computed: {
    showDeleteAll() {
      return this.selected.length > 0 && this.delete;
    },
  },
  methods: {
    showMoreName(id) {
        this.$set(this.readMorename, id, true);
    },
    showLessName(id) {
        this.$set(this.readMorename, id, false);
    },
    showMoreMain_category(id) {
        this.$set(this.readMoremain_category, id, true);
    },
    showLessMain_category(id) {
        this.$set(this.readMoremain_category, id, false);
    },
    showMoreSub_category(id) {
        this.$set(this.readMoresub_category, id, true);
    },
    showLessSub_category(id) {
        this.$set(this.readMoresub_category, id, false);
    },
    showMoreType(id) {
        this.$set(this.readMoretype, id, true);
    },
    showLessType(id) {
        this.$set(this.readMoretype, id, false);
    },
    showMoreTask(id) {
        this.$set(this.readMoretask, id, true);
    },
    showLessTask(id) {
        this.$set(this.readMoretask, id, false);
    },
    showMoreDescription(id) {
        this.$set(this.readMoredescription, id, true);
    },
    showLessDescription(id) {
        this.$set(this.readMoredescription, id, false);
    },
    showMoreCommand(id) {
        this.$set(this.readMorecommand, id, true);
    },
    showLessCommand(id) {
        this.$set(this.readMorecommand, id, false);
    },
    showMoreTips(id) {
        this.$set(this.readMoretips, id, true);
    },
    showLessTips(id) {
        this.$set(this.readMoretips, id, false);
    },
    showMoreTags(id) {
        this.$set(this.readMoretags, id, true);
    },
    showLessTags(id) {
        this.$set(this.readMoretags, id, false);
    },
    getAction(data, action, index) {
      const params = {
        data: data,
        action: action,
        selected: this.selected,
        index: index,
      };

      this.data = data;
      this.$emit("action", params);
    },
    openCommand(val){
      this.getAction(val, 'open')
    },
    updateLimiPage() {
      const data = { limit: this.limit, page: this.page };
      this.$emit("updateLimitPage", data);
    },
    updateSearch() {
      this.$emit("updateSearch", this.search);
    },
    updateSort() {
      this.$emit("updateSort", this.sort);
    },
    updateVisible() {
      this.$emit("updateVisible", this.visible);
    },
    exportToCSV() {
      const data = this.items; // Replace with your actual data source
      let csvContent = "data:text/csv;charset=utf-8,";

      // Adding header row
      const headers = Object.keys(data[0]);
      csvContent += headers.join(",") + "\r\n";

      // Adding each row
      data.forEach(row => {
      const rowContent = headers.map(header => JSON.stringify(row[header], (_, value) => value === null ? '' : value)).join(",");
      csvContent += rowContent + "\r\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data_table.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link);
    },
    exportToExcel() {
      const data = this.items; // Replace with your actual data source
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      // Generate buffer
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // Create a Blob
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      // Create an anchor element and download
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data_table.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    exportToPDF() {
      const element = document.getElementById('table'); // Replace with your table's ID
      html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({ orientation: 'landscape' });
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('data_table.pdf');
      });
    },
    importExcel(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });                        
        const firstSheetName = workbook.SheetNames[0];                      
        const worksheet = workbook.Sheets[firstSheetName];                        
        const jsonData = XLSX.utils.sheet_to_json(worksheet);                        
        for(var i =0; i<jsonData.length; i++){
            const data = {
              name: jsonData[i].name,
              main_category: jsonData[i].mainCategory,
              sub_category: jsonData[i].subCategory,
              description: jsonData[i].description,
              tips: jsonData[i].tips,
              type: jsonData[i].type,
              tags: jsonData[i].tags,
              command: jsonData[i].command,
            }
            this.addCommand(data);
        }
      };
      reader.onerror = (e) => {
        console.error("Error reading Excel file: ", e);
      };
      reader.readAsArrayBuffer(file);
    }, 
    importFile() {
      const fileInput = this.$refs.fileInput.$refs.input;
      const file = fileInput.files[0];
      if (!file) {
        console.error("No file selected for import.");
        return;
      }
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'csv') {
        this.importCSV(file);
      } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        this.importExcel(file);
      } else {
        console.error("Unsupported file type.");
      }
    },
    importCSV(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log("CSV file read successfully.");
        this.processCSVData(e.target.result);
      };
      reader.onerror = (e) => {
        console.error("Error reading CSV file: ", e);
      };
      reader.readAsText(file);
    },
    processCSVData(csvText) {
      const rows = csvText.split("\n");
      if (rows.length === 0) {
        console.error("CSV file is empty or invalid format.");
        return;
      }
      const headers = rows[0].split(",");
      const newData = rows.slice(1).map(row => {
        const values = row.split(",");
        return headers.reduce((object, header, index) => {
          object[header.trim()] = values[index]?.trim() || '';
            return object;
          }, {});
        });
        console.log(newData)

        /*this.items = [...this.items, ...newData];
        for(var i =0; i<newData.length; i++){
            const data = {
              name: newData[i].name,
              main_category: newData[i].mainCategory,
              sub_category: newData[i].subCategory,
              description: newData[i].description,
              tags: newData[i].tags,
              command: newData[i].command,
            }
            this.addCommand1(data);
        }*/
       console.log("CSV data imported successfully.");
    },
    addCommand(data) {
      this.addCommands(data).then((res) => {
        this.items = res
        this.$store.dispatch("command/getData", res)

      })
    },
    hideID(){
      this.hide_id = !this.hide_id;
      this.$emit("hideID", this.hide_id);
    },
    hideMagusAI(){
      this.hide_MagusAI = !this.hide_MagusAI;
      this.$emit("hideMagusAI", this.hide_MagusAI);
    },
    hideName(){
      this.hide_name = !this.hide_name;
      this.$emit("hideName", this.hide_name);
    },
    hideMain(){
      this.hide_main = !this.hide_main;
      this.$emit("hideMain", this.hide_main);
    },
    hideSub(){
      this.hide_sub = !this.hide_sub;
      this.$emit("hideSub", this.hide_sub);
    },
    hideType(){
      this.hide_type = !this.hide_type;
      this.$emit("hideType", this.hide_type);
    },
    hideTask(){
      this.hide_task = !this.hide_task;
      this.$emit("hideTask", this.hide_task);
    },
    hideDescription(){
      this.hide_description = !this.hide_description;
      this.$emit("hideDescription", this.hide_description);
    },
    hideCommand(){
      this.hide_command = !this.hide_command;
      this.$emit("hideCommand", this.hide_command);
    },
    hideTips(){
      this.hide_tips = !this.hide_tips;
      this.$emit("hideTips", this.hide_tips);
    },
    hideTags(){
      this.hide_tags = !this.hide_tags;
      this.$emit("hideTags", this.hide_tags);
    },
    hideAuthor(){
      this.hide_author = !this.hide_author;
      this.$emit("hideAuthor", this.hide_author);
    },
    hideAction(){
      this.hide_action = !this.hide_action;
      this.$emit("hideAction", this.hide_action);
    },
    getSelectItem(item) {
      switch (item.type) {
        case "subscription":
          const data = item.data.map((el) => {
            return parseInt(el);
          });
          this.items[item.index].subscription_id = data;
          this.selected.map((element) => {
            if (this.items[item.index].subliminal_id == element.subliminal_id) {
              element.subscription_id = data;
            }
          });
          break;
        case "statusMood":
          this.state.mood = item.data;
          break;
        case "audiotype":
          this.items[item.index].audio_type_id = item.data;

          this.selected.map((element) => {
            if (this.items[item.index].track_id == element.track_id) {
              element.audio_type_id = item.data;
            }
          });
          break;
        case "category":
          this.items[item.index].category_id = item.data;

          this.selected.map((element) => {
            if (this.items[item.index].subliminal_id == element.subliminal_id) {
              element.category_id = item.data;
            }
          });
          break;
        case "version":
          this.selected.map((element) => {
            if (this.items[item.index].track_id == element.track_id) {
              element.version = item.data;
            }
          });
          break;
        case "volume":
          this.selected.map((element) => {
            if (this.items[item.index].title == element.title) {
              element.volume = item.data;
            }
          });
          break;
      }
    },
    
  },
};
</script>

<style scoped>
.card-div {
  position: relative;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  min-height: 0%;
  padding: 10px;  

  background: #ffffff;
  border: 0.5px solid #6c757d;
}
.see {
  text-decoration: underline;
  color: #1976d2;
}
.search-text {
  padding-left: 10px;
  width: 390px;
  margin: auto;
  border-radius: 10px;
  border: solid 0.5px black;
  height: 40px;
  background: white;
  font-family: "Roboto-Regular";
}
.text-limit-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  font-family: "Roboto-Medium";
  -webkit-box-orient: vertical;
}
p{
  font-family: "Roboto-Medium";
}
.small-btn {
  margin: 4px 2px;
  color: white;
  border-radius: 100px;
  box-shadow: 0 3px 6px rgba(0, 123, 255, 0.3);
}

.add-btn, .delete-all-btn {
  color: white !important;
}

.btn-icon {
  min-width: 36px !important;
  max-width: 36px !important;
  padding: 0px 10px !important;
}
.box-grid .box {
  border: 1px solid gray;
  padding: 20px;
  margin: 10px;
  text-align: center;
}
.box-grid .image-placeholder {
  height: 200px;
  border: 1px solid gray;
  margin: 0 auto; /* Center the image box */
  background-color: #f0f0f0; /* Placeholder background, change as needed */
}
.box-grid .box-content {
  margin-top: 10px;
}
.box-content{
  min-height: 120px;
}
.box-grid .box:hover {
  border-color: #007bff; /* Change the border color on hover */
  background-color: #f8f9fa; /* Slight background color change */
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3); /* Soft shadow for depth */
  cursor: pointer; /* Change cursor to indicate clickability */
}
.box-grid .image-placeholder:hover {
  border-color: #007bff; /* Change the border color on hover */
}

</style>
