<template>
  <div>
    <PersonalCollection />
  </div>
</template>

<script>
import PersonalCollection from '../views/PersonalCollection/index'
export default {
  components: {
    PersonalCollection
  },
}
</script>