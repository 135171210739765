<template>
  <DialogAll :dialog="dialog" :width="600" @closeDialog="closeDialog">
    <div class="mx-auto text-center" style="background: white; height: 88vh; overflow: scroll;">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <v-tabs vertical v-model="currentTab">
              <v-tab>Command</v-tab>
                <v-tab-item>
                  <h3 class="title_label">Input Contents</h3>
                  <v-textarea outlined v-model="data.command" label="Command"></v-textarea>
                  <v-btn color="primary" @click="goToNextTab">Process Content</v-btn>
                </v-tab-item>

              <v-tab>Parameters</v-tab>
                <v-tab-item>
                  <h3 class="title_label">Generated Fields</h3>
                  <v-container>
                    <v-row v-for="(placeholder, index) in placeholders" :key="index">
                      <v-textarea rows="2" v-model="placeholders[index].value" :label="placeholder.name"></v-textarea>
                    </v-row>
                  </v-container>
                  <v-container style="margin-top: -30px;">
                    <div v-for="(element, index) in elements" :key="index">
                      <v-row v-if="element.type === 'textarea'">
                        <v-col cols="12">
                          <v-textarea style="margin-bottom: -30px;" v-model="element.value" :label="element.label" outlined dense></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-if="element.type === 'dropdown'">
                        <v-col cols="12">
                          <v-select style="margin-bottom: -30px;" v-model="element.selectedItem" :items="element.items" :label="element.title" outlined dense></v-select>
                        </v-col>
                      </v-row>
                      <div v-if="element.type === 'guide'" style="text-align: left; margin-top: 10px; margin-bottom: 10px;">
                        <strong>{{ element.text }}</strong>
                      </div>
                    </div>
                  </v-container>

                  <h3 class="title_label" style="margin-top: 20px;">Additional Parameters</h3>
                  <v-container>
                    <v-row v-for="(parameter, index) in additionalParameters" :key="index">
                      <v-col cols="10">
                        <v-textarea v-model="parameter.value" outlined :label="parameter.name"></v-textarea>
                      </v-col>
                      <v-col cols="2">
                        <v-btn icon @click="removeParameter(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-text-field v-model="newParameterName" label="Add Parameter" solo></v-text-field>
                    <v-btn color="primary" @click="addParameter"  style="margin-top: -20px;">Add Parameter</v-btn>
                  </v-container>

                  <h3 class="title_label"  style="margin-top: 20px;">Tips</h3>
                  <v-container>
                    <v-row>
                      <v-col>
                        <div class="tips-text">{{ data.tips }}</div>
                      </v-col>
                    </v-row>    
                  </v-container>

                  <v-btn color="primary" @click="updatePrompt">Submit</v-btn>
                  
                </v-tab-item>

              <v-tab> Run </v-tab>
                  <v-tab-item>
                    <h3 class="title_label">Updated Prompt</h3>
                    <v-textarea v-model="updatedPrompt" outlined label="Prompt"></v-textarea>
                    <v-btn color="primary" @click="callAI">Run AI</v-btn>
                  </v-tab-item>
                
              <v-tab> Output </v-tab>
                <v-tab-item>
                  <h3 class="title_label">AI Output</h3>
                  <v-textarea v-model="AIOutput" label="Output" outlined readonly></v-textarea>
                  <v-btn color="secondary" @click="copyOutput" style="margin-right: 10px;">Copy</v-btn>
                  <v-btn color="primary" @click="saveAIOutput" style="margin-right: 10px;">Save AI Output</v-btn>
                  <v-btn color="secondary" @click="clearFields" style="margin-right: 10px;">Clear Fields</v-btn>
                </v-tab-item>
                  
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </DialogAll>
</template>

<script>
import DialogAll from '@/components/DialogAll.vue'

import { updateCommands } from '@/api/command.js'
import { addHistory } from '@/api/history.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    },
  },
  components: {
    DialogAll,
  },
  data: () => {
    return {
      updateCommands,
      addHistory,

      currentTab: 0,
      currentTab: 0,
       
        //modelSelection: null,
        inputContent: '',
        placeholders: [],
        elements: [], 
        additionalParameters: [],
        newParameterName: '',
        updatedPrompt: '',
        AIOutput: '',
        models: [
          "gpt-4 turbo", 
          "gpt-4", 
          "gpt-4-32k", 
          "gpt-3.5-turbo", 
          "gpt-3.5-turbo-16k", 
          "gpt-3.5-turbo-instruct"
        ],

        quill: null,
        editorInitialized: false,
        page: 1,                    
        itemsPerPage: 10,                    
        itemsPerPageOptions: [10, 20, 50, 100, 'All'],                    
        drawer: false,
        file: null,

    }
  },
  created() {
    this.openCommand()
  },
  watch: {
    currentTab(newVal, oldVal) {
      if (newVal === 'Notebook' && !oldVal === 'Notebook') {
        this.initializeQuill();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeQuill();
    });
  },
  computed: {
    secretKey() {
      return localStorage.getItem('secret');
    },
    user() {
      return this.$store.state.user.data.user;
    },
    modelSelection() {
      return localStorage.getItem('model');
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
      openCommand() {
      this.inputContent = this.data.command
      this.processContent(); // Directly call processContent from the root Vue instance
      this.drawer=!this.drawer;
    },
    initializeQuill() {
                    // Check if the Quill editor container is present in the DOM
                    // not working render
      if (document.getElementById('notebook-editor')) {
        this.quill = new Quill('#notebook-editor', { theme: 'snow' });
      }
      if (!this.quillInitialized && this.currentTab === 'Notebook') {
        this.quill = new Quill('#notebook-editor', { theme: 'snow' });
        this.quillInitialized = true;
      }
    },
  initializeQuill() {
                    // Check if the Quill editor container is present in the DOM
                    // Working render
    if (document.getElementById('editor')) {
      this.quill = new Quill('#editor', { theme: 'snow' });
    }
  },
  goToNextTab() {
    if (this.currentTab < 5) {
      this.currentTab += 1;
    }
  },
  processContent() {
    const textareaRegex = /\[([^\]]+)\]/;
    const dropdownRegex = /<title="([^"]+)"; items="([^"]+)"(?:,"([^"]+)")*;/;
    const guideRegex = /\{([^}]+)\}/;
    this.inputContent.split('\n').forEach(line => {
      if (textareaRegex.test(line)) {
        let match = line.match(textareaRegex);
        this.elements.push({ type: 'textarea', label: match[1], value: '' });
      } else if (dropdownRegex.test(line)) {
        let match = line.match(dropdownRegex);
        if (match) {
          let title = match[1];
          let itemsString = line.substring(line.indexOf('items="') + 7, line.lastIndexOf('";'));
          let items = itemsString.split('","').map(item => item.trim().replace(/^"|"$/g, ''));
          items.unshift(''); 
          this.elements.push({ type: 'dropdown', title, items, selectedItem: '' });
        }
      } else if (guideRegex.test(line)) {
        let match = line.match(guideRegex);
        this.elements.push({ type: 'guide', text: match[1] });
      }
    });
    this.currentTab = 1;
  },
  addParameter() {
    if (!this.newParameterName) return;
      this.additionalParameters.push({ name: this.newParameterName, value: '' });
      this.newParameterName = '';
  },
  removeParameter(index) {
    this.additionalParameters.splice(index, 1);
  },
  updatePrompt() {
    let missingFields = [];
    this.elements.forEach(element => {
      if ((element.type === 'textarea' && !element.value) || 
        (element.type === 'dropdown' && !element.selectedItem)) {
          missingFields.push(element.label || element.title);
        }
      });

      if (missingFields.length > 0) {
        this.showMissingFieldsDialog(missingFields);
          return;
      }
      let content = this.inputContent;
      this.elements.forEach(element => {
        if (element.type === 'textarea') {
          let replacer = new RegExp(`\\[${element.label}\\]`, 'g');
          content = content.replace(replacer, element.value);
        } else if (element.type === 'dropdown') {
          if (element.selectedItem) {
            // Correctly handle dropdown replacements
            let dropdownRegex = new RegExp(`<title="${element.title}"; items="[^"]*"(?:,"[^"]*")*;>`, 'g');
            // Ensure the title is not duplicated in the output
            content = content.replace(dropdownRegex, `${element.selectedItem}`);
          }
        }
      });
      content = content.replace(/\{[^}]+\}\s*\n?/g, ''); // Remove guide text and its line

      // Incorporate the additional parameters logic
      let additionalParametersText = this.additionalParameters.map(p => `${p.name}: ${p.value}`).join('\n');
      if (additionalParametersText) {
        content += "\n------\n" + additionalParametersText;
      }

    this.updatedPrompt = content.trim();
    let user = localStorage.getItem('userID');
    const data = {
      user_id: user,
      command: content.trim(),
    };
    this.addHistory(data).then((res) => {
      console.log("Save to History")
    })
    this.goToNextTab();
  },
  callAI() {
    if (this.secretKey == '' || this.modelSelection == '' || this.modelSelection == null || this.secretKey == null) {
      alert("Please enter the OpenAI secret key or Select AI Model");
      return;
    }
    const apiUrl = "https://api.openai.com/v1/chat/completions";
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + this.secretKey,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "model": this.modelSelection,
        "messages": [
          {"role": "system", "content": "You are a helpful assistant."},
          {"role": "user", "content": this.updatedPrompt}
        ],
        "max_tokens": 1400
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      return response.json();
    })
    .then(data => {
      this.AIOutput = data.choices[0].message.content.trim();
      this.goToNextTab();
    })
    .catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
      this.AIOutput = "Error: " + error.message;
    });
  },

  clearFields() {
    this.inputContent = '';
    this.elements = [];
    this.placeholders = [];
    this.additionalParameters = [];
    this.newParameterName = '';
    this.updatedPrompt = '';
    this.AIOutput = '';
    this.currentTab = 0;
  },
  copyOutput() {
    navigator.clipboard.writeText(this.AIOutput).then(() => {
      console.log('Output copied to clipboard');
    }, (err) => {
    console.error('Failed to copy output: ', err);
    });
  },
  saveAIOutput() {
    if (this.AIOutput) {
      this.downloadText(this.AIOutput, 'ai_output.txt');
    }
  },
  downloadText(text, filename) {
    const blob = new Blob([text], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
   
  }
}
</script>

<style>
.colorcard .el-color-picker__trigger {
  width: 80px;
}
.title_label{
  color: #5B5EA6;
}
.tips-text {
  font-family: "Roboto-Medium";
  margin-bottom: 20px;

  white-space: pre-line; 
  word-wrap: break-word;
  text-align: left; 
}
</style>
