import request from "@/utils/request";

export const getUsers = (data) => {
  return request.get("/api/user").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const userSignIn = (data) => {
  return request.post("/api/login", data).then((res) => {
    return res.data;
  });
};

export const userSignUp = (data) => {
  return request.post("/api/register", data).then((res) => {
    return res.data;
  });
};
