<template>
  <v-container>
      <div class="inputs">
        <v-divider class="divider"></v-divider>
        <h5 v-show="email!=''" class="h51">
          <span>Email</span>
          <span class="input-invalid">{{ email_valid }}</span>
        </h5>
        <v-text-field flat placeholder="E-mail Address"
        v-model="email" class="shrink"></v-text-field>
      </div>
      <div class="inputs">
        <v-divider class="divider"></v-divider>
        <h5 v-show="password!=''" class="h51">
          <span>Password</span>
          <span class="input-invalid">{{password_valid}}</span>
        </h5>
        <v-text-field flat class="shrink" :type="showPassword ? 'text' : 'password'"
          placeholder="Password" v-model="password" @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
      </div>
      <p v-if="error!=''" style="color: red">{{error}}</p>
      <v-btn type="submit" @click="login" color="primary" :disabled="!isLoginReady">Login</v-btn>
      <h6>
        <span>Don’t have an account? </span>
        <span @click="goToRoute('/register')" class="sign-up mt-5" >Sign up</span>
      </h6>

  </v-container>
</template>

<script>
import { userSignIn } from "@/api/user";

export default {
  data: () => {
    return {
      userSignIn,
      email: '',
      password: '',
      showPassword: false,
      error: '',
      password_valid: '',
      email_valid: ''

    };
  },
  watch: {
    email(value) {
      if (value != "") {
        this.message = "";
        this.validateEmail(value);
      }
    },
    password(value) {
      if (value != "") {
        this.message = "";
        this.validatePassword(value);
      }
    },
  },
  computed: {
    isLogin() {
      return this.checkLogin();
    }, 
    isLoginReady() {
      return this.checkForm();
    },
  },
  mounted() {
    //this.login()
  },
  methods: {
    login() {
      if(this.isLogin) {
        //this.goToRoute('/free-table');
        //let data = localStorage.getItem('userData')
        //console.log(data)
      }else{
        const data = {
          email: this.email,
          password: this.password,
        };
        this.userSignIn(data).then((res) => {
          const { success, data } = res;
          if(success) {
            this.$store.dispatch('user/getToken', data.token);
            this.$store.dispatch('user/getData', data);
            localStorage.setItem('userToken', data.token);
            localStorage.setItem('userData', data);
            localStorage.setItem('userID', data.user.user_id);
            if(data.user.is_admin == 1){
              this.goToRoute('/admin-table');
            }else{
              if(data.user.subscription_id == 1){
                this.goToRoute('/free-table');
              }else{
                this.goToRoute('/premium-table');
              }
            }
          }else{
            this.email = "";
            this.password = "";
            this.error = res.message;
            
            setTimeout(() => {
              this.error = "";
            }, 3000);
          }
        });
      }
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_valid = "";
      } else {
        this.email_valid = " Email is not valid";
      }
    },
    validatePassword(password) {
      if (password.length < 8) {
        this.password_valid = " Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.password_valid = "";
            } else {
              this.password_valid = " At least one uppercase letter";
            }
          } else {
            this.password_valid = " At least one lowercase letter";
          }
        } else {
          this.password_valid = " At least one number";
        }
      }
    },
    checkForm() {
      return this.email != "" && this.password != "" && this.email_valid == "" && this.password_valid == "" && this.error == "";
    },
    checkLogin() {
      return (
        localStorage.getItem("userToken") != null
      )
    },
    goToRoute(route) {
      this.$router.push(route).catch((err) => {});
    },
  },
};
</script>

<style scoped>
h6 {
  font-family: "Montserrat-Regular";
  margin-top: 20px;
}
.sign-up {
  text-decoration: underline;
  color: #427ab3;
  cursor: pointer;
  font-family: "Montserrat-Bold";
}
.input-invalid {
  color: red;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 600;
}

</style>
