export const user = {
  namespaced: true,
  state: {
    data: [],
    history: [],
    token: null,
    secret: null,
    model: null,
    key: null,
  },
  getters: {},
  actions: {
    getData({ commit }, data) {
      commit("getData", data);
    },
    getHistory({ commit }, data) {
      commit("getHistory", data);
    },
    getModel({ commit }, data) {
      commit("getModel", data);
    },
    getSecret({ commit }, data) {
      commit("getSecret", data);
    },
    getToken({ commit }, data) {
      commit("getToken", data);
    },
    getKey({ commit }, data) {
      commit("getKey", data);
    },
  },
  mutations: {
    getData(state, data) {
      state.data = data;
    },
    getHistory(state, data) {
      state.history = data;
    },
    getModel(state, data) {
      state.model = data;
    },
    getSecret(state, data) {
      state.secret = data;
    },
    getToken(state, data) {
      state.token = data;
    },
    getKey(state, data) {
      state.key = data;
    },
  },
};
