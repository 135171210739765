<template>
  <v-dialog id="dialog" persistent v-model="dialog">
    <v-card id="card-dialog" class="card_1">
      <v-card-title class="d-flex align-self-center px-4">
        <div class="d-flex">
          <v-icon class="mx-2" color="#1976d2">{{ icon }}</v-icon>
          <h5 class="title-header">{{ title }}</h5>
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog" size="25" class="close-btn">mdi-close</v-icon>
      </v-card-title>

      <v-card-text style="padding: 0 15px;">
        <slot></slot>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean
    },
    icon: {
      type: String
    },
    title: {
      type: String
    },
    width: {
      type: Number
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped>
.card_1{
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100vh;
  overflow: scroll;
  background: #F0F0F0;
}
.close-btn {
  text-decoration: underline; 
}
.close-btn, .title-header  {
  color: #1976d2; 
  margin-right: 10px; 
  margin-top: 10px; 
}

</style>