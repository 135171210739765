import axios from "axios";

//const url = process.env.VUE_APP_BASE_URL || "http://localhost:8000";
const url = process.env.VUE_APP_BASE_URL || "https://dev.ai.magusaudio.com";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const type = urlParams.get("type");

const service = axios.create({
  baseURL: url,
  timeout: 60000,
});

service.interceptors.response.use(
  (response) => {

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
