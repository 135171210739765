<template>
  <div class="grid-1">

    <TableFree itemkey="id" id="table" :loading="loading" :items="items" :headers="headers" :select="selected"
      :total="total" @action="getAction" @updateLimitPage="updateLimitPage" @updateSearch="updateSearch"/>
    <addDialog v-if="dialogType == 'add'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />
    <editDialog v-if="dialogType == 'update'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />
    <writerDialog v-if="dialogType == 'open'" :data="data"  :dialog="dialog1" @closeDialog="closeDialog" />
    <deleteDialog v-if="dialogType == 'delete' || dialogType == 'deleteall'" :data="data" :dialog="dialog"
      :selected="selected" @selected="getSelected" @closeDialog="closeDialog" />

  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import Card from '@/components/Card.vue'
import TableFree from '@/components/Table/TableFree.vue'
import Select from '@/components/Select.vue'
import addDialog from './components/addDialog.vue'
import editDialog from './components/editDialog.vue'
import deleteDialog from './components/deleteDialog.vue'
import writerDialog from './components/writerDialog.vue'
import { getCommandsFree, upload, updateCommands, deleteCommands, addCommands } from '@/api/command';

export default {
  components: {
    TextEditor,
    TableFree,
    Card,
    Select,
    addDialog,
    editDialog,
    deleteDialog,
    writerDialog
  },
  data: () => {
    return {
      getCommandsFree,
      addCommands,
      dialog: false,
      dialog1: false,
      dialogType: null,

      data: {},

      items: [],
      selected: [],
      loading: true,

      headers1: [
        { text: 'ID', value: 'count_id', sortable: true, },
        { text: 'Magus AI ID', value: 'magus_ai_id', sortable: true },
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Main Category', value: 'main_category', sortable: true },
        { text: 'Sub Category', value: 'sub_category', sortable: true },
        { text: 'Type', value: 'type', sortable: true },
        { text: 'Description', value: 'description', sortable: true },
        { text: 'Command', value: 'command', sortable: true },
        { text: 'Tips', value: 'tips', sortable: true },
        { text: 'Tags', value: 'tags', sortable: true },
        { text: 'Action', value: 'update_delete', sortable: false },
      ],

      hide_id_obj:  { text: 'ID', value: 'count_id', align: ' d-none', sortable: true, },
      id_obj:  { text: 'ID', value: 'count_id', sortable: true, },

      hide_magus_id_obj: { text: 'Magus AI ID', value: 'magus_ai_id', align: ' d-none', sortable: true },
      magus_id_obj: { text: 'Magus AI ID', value: 'magus_ai_id', sortable: true },

      hide_name_obj: { text: 'Name', value: 'name', align: ' d-none', sortable: true },
      name_obj: { text: 'Name', value: 'name', sortable: true },
      
      hide_main_obj: { text: 'Main Category', value: 'main_category', align: ' d-none', sortable: true },
      main_obj: { text: 'Main Category', value: 'main_category', sortable: true },

      hide_sub_obj: { text: 'Sub Category', value: 'sub_category', align: ' d-none', sortable: true },
      sub_obj: { text: 'Sub Category', value: 'sub_category', sortable: true },
      
      hide_type_obj: { text: 'Type', value: 'type', align: ' d-none', sortable: true },
      type_obj: { text: 'Type', value: 'type', sortable: true },
      
      hide_task_obj: { text: 'Task', value: 'task', align: ' d-none', sortable: true },
      task_obj: { text: 'Task', value: 'task', sortable: true },
      
      hide_description_obj: { text: 'Description', value: 'description', align: ' d-none', sortable: true },
      description_obj: { text: 'Description', value: 'description', sortable: true },
      
      hide_command_obj: { text: 'Command', value: 'command', align: ' d-none', sortable: true },
      command_obj: { text: 'Command', value: 'command', sortable: true },
      
      hide_tips_obj: { text: 'Tips', value: 'tips', align: ' d-none', sortable: true },
      tips_obj: { text: 'Tips', value: 'tips', sortable: true },
      
      hide_tags_obj: { text: 'Tags', value: 'tags', align: ' d-none', sortable: true },
      tags_obj: { text: 'Tags', value: 'tags', sortable: true },
      
      hide_author_obj: { text: 'Author', value: 'author', align: ' d-none', sortable: true },
      autho_obj: { text: 'Author', value: 'author', sortable: true },
      
      hide_action_obj: { text: 'Action', value: 'update_delete', align: ' d-none', sortable: false },
      action_obj: { text: 'Action', value: 'update_delete', sortable: false},

      headers: [],

      sort: null,
      status: null,
      search: null,

      page: 1,
      total: 0,
      limit: 10,

      list: null,
      texteditorvalue: null,

    }
  },
  created() {
    this.getData()
    this.headers = [this.id_obj, this.magus_id_obj, this.name_obj, 
    this.main_obj, this.sub_obj, this.type_obj, this.description_obj,
    this.command_obj, this.tips_obj, this.tags_obj, this.action_obj]
  },
  computed: {
    isLogin() {
      return this.checkLogin();
    }, 
  },
  mounted() {
    this.login()
  },
  methods: {
    listchange(val) {
      this.texteditorvalue = val;
    },
    login(){
      if(this.isLogin){
        this.goToRoute('/login')
      }
    },
    goToRoute(route) {
      this.$router.push(route).catch((err) => {});
    },
    checkLogin() {
      return localStorage.getItem("userToken") == null

    },
    getAction(params) {
      this.dialog = true
      this.dialogType = params.action
      this.data = params.data
      this.selected = params.selected

      if (params.action == 'open') {
        this.dialog1 = true
      }
    },
    getSelected(data) {
      this.selected = data
    },
    closeDialog() {
      this.dialog = false
      this.dialogType = ''
      this.getData()
    },
    getData() {
      this.loading = true
      let user = localStorage.getItem('userID');
      
      const data = {
        author: user,
        page: this.page,
        limit: this.limit,
        search: this.search,
      }
      this.getCommandsFree(data).then((res) => {
        const { command, total_pages } = res
        this.total = total_pages
        this.items = command
        this.loading = false
      })
    },
    getSelectItem(item) {
      switch (item.type) {
        case 'statemood':
          this.status = item.data
          break;
      }
    },
    addCommand(data) {
      this.addCommands(data).then((res) => {
        this.items=(res)
      })
    },
    // Add other necessary methods here
    updateLimitPage(data) {
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    },
    updateSearch(data) {
      this.search = data
      this.getData()
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    },
    exportToCSV() {
      const data = this.items; // Replace with your actual data source
      let csvContent = "data:text/csv;charset=utf-8,";

      // Adding header row
      const headers = Object.keys(data[0]);
      csvContent += headers.join(",") + "\r\n";

      // Adding each row
      data.forEach(row => {
      const rowContent = headers.map(header => JSON.stringify(row[header], (_, value) => value === null ? '' : value)).join(",");
      csvContent += rowContent + "\r\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data_table.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link);
    },
    exportToExcel() {
      const data = this.items; // Replace with your actual data source
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      // Generate buffer
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // Create a Blob
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      // Create an anchor element and download
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data_table.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    exportToPDF() {
      const element = document.getElementById('table'); // Replace with your table's ID
      html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({ orientation: 'landscape' });
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('data_table.pdf');
      });
    },
  }
}
</script>

<style scoped>

.grid-1 {
  background: white;
}
.top_container {
  padding-top: 40px;
  margin-bottom: -80px;
}
.ai_model {
  width: 40%; 
  margin-left: 8%; 
  margin-right: 2%;
}
.ai_secret {
  width: 40%; 
  margin-left: 2%; 
  margin-right: 8%;
}
.text {
  color: #5B5EA6;
}
.small-btn {
  background: #1976d2 !important
}
</style>