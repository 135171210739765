<template>
  <div class="footer">
    <p>Contact us at: info@magusaudio.com</p>
    <p>&copy; Copyright 2023 Magus Technology Inc</p>
  </div>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.footer {
  background: linear-gradient(to right, #017AFF 0%, #5DAAFF 50%, #5DAAFF 100%);
  color: white;
  padding: 15px;
  text-align: center;
}
p{
  font-family: "Roboto-Regular";
  margin: 0px;
}
</style>