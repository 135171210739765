<template>
  <div>
    <!-- <p class="mb-1">{{ title }}</p> -->
    <v-autocomplete solo dense hide-details clearable :multiple="isMultiple" v-model="item" :items="items"
      :item-text="text" :item-value="value" @change="getSelectItem" :label="title" />
  </div>
</template>

<script>
export default {
  props: {
    data: {},
    type: {
      type: String
    },
    text: {
      type: String
    },
    value: {
      type: String
    },
    title: {
      type: String,
      default: () => {
        return null
      }
    },
    index: {
      type: Number,
      default: () => {
        return 0
      }
    },
    custom: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => {
    return {
      item: null
    }
  },
  computed: {
    items() {
      return this.custom.length > 0 ? this.custom : []
    },
  },
  created() {
    this.item = this.data
  },
  methods: {
    getSelectItem() {
      const data = {
        type: this.type,
        data: this.item,
        index: this.index
      }

      this.$emit("getSelectItem", data)
    }
  }
}
</script>