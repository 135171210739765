<template>
  <div class="header d-flex">
    <div>
      <img src="@/assets/images/logo.png" class="logo-png" alt="">
    </div>
    <div class="header-tools d-flex justify-content-between align-center">
      <div class="ml-2 mt-3 ai-magus">
        <h2 class="h2-mg">AI Assistants</h2>
        <p>By: Magus</p>
      </div>
      <div>
        <el-dropdown
          trigger="click"
          :hide-on-click="true"
          @command="handleCommand"

        >
          <v-avatar class="ml-2" size="50" color="grey lighten-2 mr-5">
            <v-icon size="40" >mdi-account</v-icon>
          </v-avatar>
          <el-dropdown-menu slot="dropdown">
            <div class="align-center justify-center" style="background: #343a40; border-radius: 5px;">
              <p style="padding: 10px; color: white">User Type: Admin</p>
            </div>
            <el-dropdown-item
              class="d-flex py-2"
              style="width: 180px"
              command="settings"
            >
              <v-icon class="mr-4">mdi-cog</v-icon>
              <span>Settings</span>
            </el-dropdown-item>
            <el-dropdown-item
              class="d-flex py-2"
              command="history"
              style="width: 180px"
            >
              <v-icon class="mr-4">mdi-history</v-icon>
              <span>View History</span>
            </el-dropdown-item>
            <el-dropdown-item
              class="d-flex py-2"
              command="logout"
              style="width: 180px"
            >
              <v-icon class="mr-4">mdi-logout</v-icon>
              <span>Logout</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <SettingsDialog :dialog="dialog_settings" @closeDialog="closeDialog"/>
    <HistoryDialog :dialog="dialog_history" @closeDialog="closeDialog"/>
  </div>
</template>

<script>
import SettingsDialog from '@/components/SettingsDialog.vue'
import HistoryDialog from '@/components/HistoryDialog.vue'

export default {
  
  components: {
    SettingsDialog,
    HistoryDialog,
  },
  data: () => {
    return {
      dialog_settings: false,
      dialog_history: false,
    }
  },
  computed: {
  },
  methods: {
    logout(){
      this.$store.dispatch("user/getToken", null);
      this.$store.dispatch("user/getData", null);

      localStorage.removeItem("userID");
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");

      localStorage.removeItem('model')
      localStorage.removeItem('secret')

      this.$store.dispatch('user/getModel', null);
      this.$store.dispatch('user/getSecret', null);

      this.goToRoute('/login')
    },
    goToRoute(route) {
      this.$router.push(route).catch((err) => {});
    },
    handleCommand(command) {
      if (command == "logout") {
        this.logout();
      }

      if (command == "settings") {
        this.dialog_settings = true
      }

      if (command == "history") {
        this.dialog_history = true
      }
    },
    closeDialog(data) {
      this.dialog_settings = data;
      this.dialog_history = data;
    },
  }
}
</script>

<style scoped>
.ai-magus {
  text-align: left;
  color: white;
}
.h2-mg {
  color: white;
}
.header {
  background: linear-gradient(to right, #017AFF 0%, #5DAAFF 50%, #5DAAFF 100%);
  font-family: "Roboto-Medium";
  color: white;
  text-align: left;
}
.header-tools{
  width: 100%;
}

.logo-png {
  margin: 10px;
  height: 90px;
}
header-tools
h2{
  font-family: "Roboto-Bold";
}
h5{
  margin: 0px;
  font-family: "Roboto-Bold";
  cursor: pointer;
}
p{
  font-family: "Roboto-Medium";
  margin-top: -10px;
}
</style>