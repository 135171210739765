<template>
  <DialogAll :dialog="dialog" :width="600" :icon="`mdi-cog`" :title="`Settings`" @closeDialog="closeDialog">
    <div class="align-center justify-center">   
      <div class="ai_models">                   
        <h3 class="texts">Select AI Model</h3>
         <v-select v-model="modelSelection" :items="models" label="Model" solo></v-select>
      </div>  
      <div class="d-flex align-center justify-center ai_secrets">    
        <div style="width: 100%">               
          <h3 class="texts">OpenAI Secret Key</h3>
          <v-text-field v-model="secretKey" type="password" label="Secret Key" solo></v-text-field>
        </div>  
        <v-btn large class="ml-2 small-btn" @click="fillSecretKey">🔑</v-btn>
      </div>  
      <div class="d-flex" style="margin-left: 10%;">    
        <v-btn color="#1976d2 !important" class="text-white mr-2" @click="save">Save</v-btn>
        <v-btn color="#1976d2 !important" class="text-white" @click="clear">Clear Fields</v-btn>
      </div>    
    </div>
  </DialogAll>
</template>

<script>
import DialogAll from '@/components/DialogAll.vue'

export default {
  props: {
    dialog: {
      type: Boolean
    },
  },
  data: () => {
    return {
      secretKey: '',
      modelSelection: null,
      
      models: [
        "gpt-4-1106-preview", 
        "gpt-3.5-turbo-1106"
      ],
    }
  },
  components: {
    DialogAll
  },
  computed: {
    secret() {
      return this.$store.state.user.secret;
    },
    model() {
      return this.$store.state.user.model;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData(){
      if(this.secret == null){
        this.secretKey = localStorage.getItem('secret')
      }else{
        this.secretKey = this.secret;
      }
      if(this.model == null){
        this.modelSelection = localStorage.getItem('model')
      }else{
        this.modelSelection = this.model;
      }
    },
    clear() {
      this.secretKey = '';
      this.modelSelection = null;
    },
    save() {
      console.log("save")
      localStorage.setItem('model', this.modelSelection)
      localStorage.setItem('secret', this.secretKey)

      this.$store.dispatch('user/getModel', this.modelSelection);
      this.$store.dispatch('user/getSecret', this.secretKey);
      
      alert("Settings saved successfully.");
      this.closeDialog();
    },
    fillSecretKey() {
      this.secretKey = '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';
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
  }
}
</script>

<style>
.ai_models {
  margin-top: 50px;
  margin-left: 10%;
  width: 80%; 
}
.ai_secrets {
  margin-left: 10%;
  width: 80%; 
}
.texts {
  color: #5B5EA6;
  margin-top: -10px;
}

</style>