<template>
  <DialogAll :dialog="dialog" :width="600" :icon="`mdi-history`" :title="`History`" @closeDialog="closeDialog">
    <v-btn v-if="items.length != 0" color="#1976d2 !important" class="mx-3 my-2 text-white add-btn" @click="deleteAllHistorys">Clear History</v-btn>
      <span class="mx-3" v-else>No History Found.</span>
    <template v-for="(item, index) in items">
      <div class="align-center justify-start d-flex">   
        <div class="mx-3 pa-4 my-2 history" style="width: 100%">
          <span>{{item.command}}</span>
        </div>
        <v-btn color="#1976d2 !important" class="mr-3 text-white add-btn" @click="copyHistoryToClipboard(item.command)">Copy</v-btn>
        <v-btn color="red !important" class="mr-3 text-white add-btn" @click="deleteOneHistorys(item.id)">Delete</v-btn>
      </div>
    </template>

  </DialogAll>
</template>

<script>
import DialogAll from '@/components/DialogAll.vue'
import { showAllHistory, deleteOneHistory, deleteAllHistory } from '@/api/history.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
  },
  data: () => {
    return {
      showAllHistory,
      deleteOneHistory,
      deleteAllHistory,
      items: []
    }
  },
  components: {
    DialogAll
  },
  computed: {
    secret() {
      return this.$store.state.user.secret;
    },
    model() {
      return this.$store.state.user.model;
    },
    user() {
      return this.$store.state.user.data.user;
    },
  },
  mounted() {
  },
  watch: {
    dialog(val){
      if(val == true){
        this.getData();
      }
    }
  },
  methods: {
    copyHistoryToClipboard(data) {
      navigator.clipboard.writeText(data).then(() => {
        console.log('Output copied to clipboard');
      }, (err) => {
      console.error('Failed to copy output: ', err);
      });
    },
    deleteAllHistorys() {
      let user = localStorage.getItem('userID');
      const data = {
        user_id: user,
      };
      this.deleteAllHistory(data).then((res) => {
        this.items = []
        alert("All history are deleted.");
      })
    },
    deleteOneHistorys(id) {
      const data = {
        id: id,
      };
      this.deleteOneHistory(data).then((res) => {
        this.items = res
      })
    },
    getData() {
      let user = localStorage.getItem('userID');
      const data = {
        user_id: user,
      };
      this.showAllHistory(data).then((res) => {
        this.items = res
      })
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
  }
}
</script>

<style>
.history {
  border: 1px solid #353535; 
  background-color: #adbdf3;
  border-radius: 15px;
  padding: 10px 15px; 
  margin: 5px 0; 
  white-space: pre-line; 
  word-wrap: break-word;
  text-align: left; 
}
</style>