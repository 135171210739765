import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";
import Table from "../pages/Table.vue";
import PureGold from "../pages/PureGold.vue";
import PersonalCollection from "../pages/PersonalCollection.vue";

import Admin from '@/pages/AiWriter/Admin.vue';
import Free from '@/pages/AiWriter/Free.vue';
import Premium from '@/pages/AiWriter/Premium.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
    },

    {
        path: '/admin-table',
        name: 'admin-table',
        component: Admin,
    },
    {
        path: '/free-table',
        name: 'free-table',
        component: Free,
    },
    {
        path: '/premium-table',
        name: 'premium-table',
        component: Premium,
    },


    {
        path: '/puregold-budgetplanner',
        name: 'puregold-budgetplanner',
        component: PureGold,
    },
    {
        path: '/personalcollection-budgetplanner',
        name: 'personalcollection-budgetplanner',
        component: PersonalCollection,
    },


    {
        path: '/table',
        name: 'table',
        component: Table,
    },
]
const router = new VueRouter({
    mode: "history",
    history: true,
    routes,
  });

export default router;