import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { user } from "./modules/user";
import { command } from "./modules/command";
import { history } from "./modules/history";

const store = new Vuex.Store({
  modules: {
    user,
    command,
    history,
  },
});

export default store;
