import request from "@/utils/request";

export const updateCommands = (data) => {
  return request
    .put(`/api/magus/command/${data.id}`, data)
    .then((res) => {
      return res.data;
    });
};


export const addCommands = (data) => {
  return request.post("/api/magus/command", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const deleteCommands = (data) => {
  return request.delete(`/api/magus/command/${data.id}`).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const upload = (data) => {
  return request.post("/api/magus/command/import", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getCommandsAdmin = (data) => {
  return request.post("/api/magus/command/filter/admin",data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getCommandsFree = (data) => {
  return request.post("/api/magus/command/filter/free",data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getCommandsPremium = (data) => {
  return request.post("/api/magus/command/filter/premium",data).then((res) => {
    const { data } = res.data;
    return data;
  });
};