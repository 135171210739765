<template>
  <div>
    <Login />
  </div>
</template>

<script>
import Login from '../views/Login'

export default {
  components: {
    Login
  },
}
</script>