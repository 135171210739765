<template>
    <div class="body">
      <div class="header d-flex align-center">
        <div style="width: 35%;">
          <img src="@/assets/images/pc.png" style="width: 60%;" alt="">
        </div>
        <div class="header">
          <h2 class="h2">#PersonalCollection</h2>
          <h1 class="h1">Shopping Planner</h1>
        </div>
      </div>
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-md-12">
                  <div class="aiForm">
                      <div class="form-group">
                          <h3 class="input-header" for="budget">Budget</h3>
                          <input type="number" class="form-control" v-model="budget" name="Budget" placeholder="Enter your budget">
                          <label class="guide-label">Define your spending limit for the grocery shopping period. Consider your overall financial plan and allocate funds accordingly.</label>
                      </div>
                      <div class="form-group">
                          <h3 class="input-header" for="noOfPersons">No. of Persons</h3>
                          <input type="number" class="form-control" v-model="noOfPersons" name="NoOfPersons" placeholder="Enter number of persons">
                          <label class="guide-label">Select the number of individuals the grocery shopping needs to cater to. This information is crucial for quantity estimations.</label>
                      </div>                     
                      <div class="form-group">
                          <h3 class="input-header" for="duration">Duration</h3>
                          <select class="form-control" v-model="duration" name="Duration" @change="toggleOtherDuration(duration)">
                              <option>Weekly</option>
                              <option>Bi-weekly</option>
                              <option>Monthly</option>
                              <option>Others please specify</option>
                          </select>
                          <input type="number" class="form-control mt-2" v-if="otherDurationInput == true" v-model="otherDuration" name="Duration" placeholder="Specify number of days">
                          <label class="guide-label">Choose the time frame for which the shopping list will be used. This helps in planning purchases and balancing perishables and non-perishables.</label>
                      </div>
                      <div class="form-group">
                          <h3 class="input-header" for="itemCategory">Item Category</h3>
                          <input type="text" class="form-control" v-model="itemCategory" name="Item Category" placeholder="Specify item categories (Example: Food, Beverage, Cleaning Items)">
                          <label class="guide-label">List down the categories to organize the shopping list and ensure all needs are met.</label>
                      </div>
                      <div class="form-group">
                          <h3 class="input-header" for="brandPreferences">Brand Preferences</h3>
                          <input type="text" class="form-control" v-model="brandPreferences" name="Brand Preferences" placeholder="Specify brand names (Example: Nestle, Personal Collection, Bonus, Unilever)">
                          <label class="guide-label">Note specific brands you prefer for certain items. This can be motivated by dietary restrictions, quality standards, or price considerations.</label>
                      </div>
                      <div class="form-group">
                          <h3 class="input-header" for="listItems">Items</h3>
                          <textarea class="form-control" v-model="listItems" name="List Items" rows="3"></textarea>
                          <label class="guide-label">Detail the items to be purchased under each category. Be as specific as necessary to ensure your shopping list is comprehensive and tailored to your preferences.</label>
                      </div>
  
                      <div class="action-buttons">
                          <button type="submit" @click="callAI">Run AI</button>
                          <button type="button" class="btn btn-primary" @click="clearFields">Clear</button>
                          <button type="button" class="btn btn-primary" @click="printOutput">Print</button> 
                      </div>
                      <div class="form-group">
                          <div id="output" style="border: 1px solid #ced4da; padding: .375rem .75rem; border-radius: .25rem; min-height: 100px; word-wrap: break-word; overflow-y: auto;">
                              <h1 style="text-align: center;">Shopping List</h1>
                              <div class="loadingOutput" v-if="loadingOutput == true">Loading...</div>
                              <div id="outputContent"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="footer">
        <div class="footer-content">
          <h4>Made with <span>❤️</span> by:</h4>
          <h3>Magus Technology Inc.</h3>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  
    export default {
      data: () => {
        return {
          loadingOutput: false,
          otherDurationInput: false,
          appConfig: {
              secretKey: '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',
              modelSelection: 'gpt-4-1106-preview',
              budgetPrompt: ''
          },
          commandPrompt: `
              **Main Instructions:**
  - Create a grocery shopping list with the following considerations:
    - **Budget:** Adhere closely to the specified budget, ensuring the total cost of items falls within the budget range.
    - **Duration:** Plan for the specified duration.
    - **Item Categories:** Include various categories of items.
    - **Brand Preferences:** Select items based on the given brand preferences.
    - **Specific Items:** Ensure all listed items are included.
  
  **Additional Instructions:**
  - **Omit Feedback and Conclusion Statements:** 
    - Do not include any feedback or conclusion statements in the output. Focus solely on presenting the grocery shopping list code right away without additional commentary.
  - **Output Format:** 
    - Present the each item in the shopping list only in pure JSON format. Do not enclose items in <ul>, <li>, <div>, or any other HTML codes.
    - Do not enclose the output in "'''json" and in markdow format and something like that.
  
  {
      "item1": {
          "Qty": 10,
          "Item": "Example Item 1",
          "Unit Price": 5.99
      },
      "item2": {
          "Qty": 5,
          "Item": "Example Item 2",
          "Unit Price": 8.49
      }
  }
  
  - **Avoid Special Characters Enclosing Output:**
   - Present the shopping list directly as HTML code without enclosing it in code box markdown format. 
   - Do not use special characters to encapsulate the HTML code. The output should be the raw HTML code.
   - Do not ever ever ever place or use a backtick symbol.
  - **Item and Price Details:**
    - **Branded Products:** Use branded names for items as indicated in the brand preferences.
    - **Local Brands:** Base brand selections on brands commonly found in the Philippines.
    - **Product Producer Brands:** 
     -- If specified as Pure Gold or PureGold, search products here: https://puregold.com.ph/pgcatalog/category/subcategory
     -- If specified as Personal Collection Products, Personal Collection, or PC, search products here: https://www.personalcollection.com.ph/great-products-for-direct-selling/
     -- If product brands seem vague, please conduct a search and navigate their website to learn about their products.
    - **Specificity in Items:** For generic items like "Bread, Water, Shampoo, Soap," provide a specific brand for each based on the brand preferences or instruction references.
    - **Pricing:** 
    - **Quantity (Qty.):** Indicate the quantity for each item.
    - **Unit Price:** 
     -- Provide the individual price of each item and indicate accurate price amounts based on your training data and current research.
     -- Prices are computed in Philippine Peso but do not write (Php.). 
     -- Write the price with 2 decimal places (Example: .00).
  
  **Total Budget Calculation Logic:** 
  - **Total Amount Alignment with Budget:** 
    - The total amount of the products listed should closely align with the provided budget, ensuring efficient spending.
    - **Example:** If the budget is 1500 Php, the total amount of products should tally around 1300-1500 Php.
    - This range represents approximately 87% to 100% of the allocated budget.
  - **Budget Utilization:**
    - Aim to utilize at least 87% of the budget, ensuring that the essential items are covered without significant underspending.
    - Avoid exceeding 100% of the budget to maintain financial discipline.
    - If the calculated total amount exceeds the budget, prioritize essential items and adjust quantities to bring the total within the specified budget range.
    - Consider reducing quantities or excluding non-essential items to align with the budget without compromising crucial purchases.
  - **Calculation of Total Price:**
    - The total price is calculated by summing the 'Total Price' column in the shopping list.
    - Ensure the final sum is within the specified budget range.
    - Ensure the final sum is not higher or greater than the specified budget in the input.
  - **JSON Output
    - Do not output the Total Price, Total Amount, Final Amount, Sum of Price of Products, in the JSON Format
  
  **Other Considerations:**
  - **Quantity Guidelines:**
    - Consider the household size; assume a standard composition if not specified. Example: For 7 people, assume 2 parents and 5 individuals with specific birth orders with balanced male to female ratio and varying ages composing of kids to teens and adult.
    - For personal items (e.g., toothbrushes, deodorant) Align item quantities with the total household members. Maintain consistency in quantities to match the household size.
  - **Food Planning:**
    - Factor in 3-4 meals daily with balanced nutrition for carbohydrates, protein, and vegetables. Snacks are optional but not mandatory.
    - Tailor the food list based on household size and meal frequency.
  - **Cleaning and other household items:**
    - Examples are: Big dishwashing liquid, detergent, disinfectant, insecticides, etc.
    - Typically, one item per type is sufficient for the entire family and should last for at least 1 week.
    - Adjust quantities based on use frequency and specific household needs.
  - **Additional Logic:**
    - Consider unique household factors for adjusting quantities and choices.
    - Ensure a holistic approach to the shopping list based on household dynamics.
  
  Product list below displayed in this format: (Product Name, Price in Php)
  ** Example Personal Collection Products List**
  - **Baby Care**
  White Dove, 214
  White Dove Little Giggles (Baby Cologne), 79
  White Dove Baby Cologne Giggles, 145
  White Dove Baby Cologne Kisses, 145
  White Dove Baby Lotion DreamScentz™, 155
  White Dove Baby Milk Soap DreamScentz™, 59
  White Dove Baby Oil DreamScentz™, 155
  White Dove Baby Powder Dreamscentz™, 155
  White Dove Baby Shampoo Dreamscentz™, 155
  White Dove Baby Wipes with Aloe Vera Wipes, 59
  
  - **Food and Beverage**
  Great Life Instant Coffee Brown (Twin Pack) 10s, 250
  Great Life Instant Coffee Creamy White (Twin Pack) 10s, 250
  Revitalife 3-in-1 Instant Coffee, 455
  Revitalife 7-in-1 Instant Coffee Mix, 485
  Revitalife Gourmet Latte, 480
  Revitalife Shape Powder, 495

  - **Fragrances**
  Aspire Obsession, 195 
  Aspire Passion, 195 
  Aspire Romance, 195 
  Chrome 55 mL, 320

  - **Healthcare**
  ChocoBright Power Drink, 295
  HBS Immuno-C (Vitamin C), 499
  HBS Immuno-C Plus, 675
  HBS LiverProtect, 205
  MilkyBright, 275
  PCMed Ascorbic Acid, 499

  - **Home Care/ Household Products/ Cleaning/ Etc.**
  Blue Wave Dishwashing Liquid, 35
  Blue Wave Powder Laundry Detergent, 135
  sof & mmmmm Naturals Fabric Conditioner, 299
  Target MIK 300 mL Insecticide, 224
  Tuff Disinfectant Spray, 249
  Tuff Surface Cleaner, 194

  - **House Ware**
  PCHOME VACUUM FLASK 22 OZ BLUE, 749
  PCHOME VACUUM FLASK 22 OZ RED, 749
  PCHOME VACUUM FLASK 32OZ BLACK, 799
  
  ==============
  INPUT:
              
  `,
          budget: '',
          noOfPersons: '',
          duration: 'Weekly',
          itemCategory: '',
          brandPreferences: '',
          otherDuration: '',
          listItems: '',
  
        }
      },
      created() {
        document.title = "Personal Collection Budget Planner"
      },
      computed: {
      },
      mounted() {
      },
      methods: {
        toggleOtherDuration(selectBox){
          if (selectBox === 'Others please specify') {
            this.otherDurationInput = true;
          } else {
            this.otherDurationInput = false;
            this.otherDuration = ''; // Clear the input when other options are selected
          }
        },
        updateBudgetPrompt() {
          let prompt = this.commandPrompt;
          if (this.budget) prompt += `Budget: ${this.budget}\n`;
          if (this.noOfPersons) prompt += `No. of Persons: ${this.noOfPersons}\n`;
          if (this.otherDurationInput == true){
            if (this.duration) prompt += `Duration: ${this.otherDuration} Days\n`;
          }else{
            if (this.duration) prompt += `Duration: ${this.duration}\n`;
          }
          if (this.itemCategory) prompt += `Item Category: ${this.itemCategory}\n`;
          if (this.brandPreferences) prompt += `Brand Preferences: ${this.brandPreferences}\n`;
          if (this.listItems) prompt += `Items: ${this.listItems}\n`;
          this.appConfig.budgetPrompt = prompt;
        },
        clearFields() {
          this.budget = ''
          this.noOfPersons = ''
          this.duration = 'Weekly'
          this.otherDuration = ''
          this.itemCategory = ''
          this.brandPreferences = ''
          this.listItems = ''
          this.output = ''
        },
        printOutput() {
          let printWindow = window.open('', '_blank');
          printWindow.document.write('<html><head><title>Print</title></head><body>');
          printWindow.document.write('<pre>' + document.getElementById('output').innerHTML + '</pre>');
          printWindow.document.write('</body></html>');
          printWindow.document.close();
          printWindow.print();
        },
        convertJsonToTable(jsonInput) {
          try {
            var jsonData = JSON.parse(jsonInput);
              this.generateTable(jsonData);
          } catch (error) {
            console.error('Invalid JSON format');
            console.error('Error parsing JSON:', error);
            document.getElementById('output').innerText = 'Please try again'; //Invalid JSON format
          }
        },
        generateTable(data) {
          var table = '<h1 style="padding-top: 30px;">Shopping List</h1>'+
                          '<table style="border-collapse: collapse; margin-left: auto; margin-right: auto; width: 100%;">' +
                              '<thead style="background-color: #FF4a2f; color: #ffffff;  border: 3px solid #FF4a2f !important; height: 50px;">' +
                                  '<tr>' +
                                      '<th>Qty.</th>' +
                                      '<th>Item</th>' +
                                      '<th>Unit Price (Php.)</th>' +
                                      '<th>Total Price (Php.)</th>' +
                                  '</tr>' +
                              '</thead>' +
                              '<tbody>';
  
              var totalAmount = 0;
  
              Object.keys(data).forEach(function(key) {
                  var item = data[key];
                  var totalPrice = item.Qty * item["Unit Price"];
                  totalAmount += totalPrice;
  
                  table += '<tr style="border: 2px solid #FF4a2f !important; height: 35px;">' +
                              '<td style="text-align: center;">' + item.Qty + '</td>' +
                              '<td>'+'<b>' + item.Item + '</b>'+'</td>' +
                              '<td style="text-align: center;">' + item["Unit Price"].toFixed(2) + '</td>' +
                              '<td style="text-align: center;">' + totalPrice.toFixed(2) + '</td>' +
                           '</tr>';
              });
  
              table += '</tbody>' +
                       '<tfoot style="margin-bottom: 50px;">' +
                          '<tr style="height: 50px; background-color: #DDDDDD;">' +
                              '<td colspan="3" style="border: 2px solid #DDDDDD !important; text-align: right;"><strong>Total Amount     :   </strong></td>' +
                              '<td colspan="1" style="border: 2px solid #DDDDDD !important; text-align: center;"><strong>' + totalAmount.toFixed(2) + '</strong></td>' +
                          '</tr>' +
                       '</tfoot>' +
                    '</table>';
  
              document.getElementById('output').innerHTML = table;
        },
        callAI() {
          this.updateBudgetPrompt(); // Update the budgetPrompt with the formatted string
  
              if (!this.appConfig.secretKey) {
                  alert("Please enter the OpenAI secret key.");
                  return;
              }
  
              this.loadingOutput = true;
  
              const apiUrl = "https://api.openai.com/v1/chat/completions";
              fetch(apiUrl, {
                  method: 'POST',
                  headers: {
                      "Authorization": "Bearer " + this.appConfig.secretKey,
                      "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                      "model": this.appConfig.modelSelection,
                      "messages": [
                          {"role": "system", "content": "You are a helpful assistant."},
                          {"role": "user", "content": this.appConfig.budgetPrompt}
                      ],
                      "max_tokens": 1400
                  })
              })
              .then(response => {
                  if (!response.ok) {
                      throw new Error('Network response was not ok ' + response.statusText);
                  }
                  return response.json();
              })
              .then(data => {
                this.loadingOutput = false
  
                  const outputText = data.choices[0].message.content.trim();
  
                  this.convertJsonToTable(outputText);
  
                  /*document.getElementById('outputContent').innerHTML = outputText;
                  //resizeOutput();
                  let outputDiv = document.getElementById('output');
                  outputDiv.style.height = ""; // Reset the height
                  outputDiv.style.height = outputDiv.scrollHeight + "px";
  
                  //updateContainerHeight();
                  let outputHeight = outputDiv.offsetHeight;
                  document.getElementById('aiForm').style.height = outputHeight + 'px';*/
  
              })
              .catch(error => {
                  console.error('There has been a problem with your fetch operation:', error);
                  document.getElementById('loadingOutput').style.display = 'none';
                  document.getElementById('outputContent').innerHTML = "Error: " + error.message;
              });
          },
  
          
      }
    }
  </script>
  
  
  <style>
      
      :root {
        --primary-color: #FF4a2f;
        --secondary-color: #ffffff;
        --text-color: #333;
      }
      .input, .guide-label {
        font-family: "Montserrat-Bold" !important;
      }

      .body{
          background-color: var(--primary-color);
          font-family: "Montserrat-Bold";
      }
  
      .aiForm{
          background-color: #ffffff;
          height: auto !important;
          line-height: 100%;
          border: 1px solid #ced4da;
          border-radius: 20px !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 100px;
          padding-right: 100px;
          margin-bottom: 50px;
      }
  
      .header {
          align-items: center;
          background-color: var(--secondary-color);
          padding-top: 10px;
          padding-bottom: 10px;
      }
  
      /*span {
          color: var(--primary-color);
          text-align: center !important;
          font-size: 30px !important;
      }*/
  
      h1 .header {
          color: var(--primary-color) !important;
          text-align: center !important;
          font-size: 100px !important;
      }
  
      .h2, .h1, .header {
          color: var(--primary-color) !important;
          text-align: center !important;
      }
  
      .logo {
          max-width: 20%;
          height: auto;
          position: absolute;
          left: 10px; /* Adjust the left position as needed */
          
      }
  
      h1 {
          color: var(--primary-color);
          text-align: center;
          font-weight: bolder;
      }
  
      h3 {
          font-weight: bold;
      }
  
      .footer {
          background-color: #f0f0f0;
          padding: 10px;
          width: 100% !important;
          text-align: center;
          position: absolute;
          padding-top: 30px;
          padding-bottom: 30px;
  
      }
  
      .input-header {
          color: var(--primary-color) !important;
          margin-bottom: 0.5rem !important; /* Adjust margin as needed */
          font-size: 20px !important;
      }
  
      form {
          margin-top: 20px; /* Adjust top margin as needed */
          padding: 20px;
          height: auto !important;
      }
  
      .form-control {
          width: 100% !important;
          margin-bottom: 15px !important; /* Add bottom margin to form controls for spacing */
          background-color: var(--secondary-color) !important;
          color: #7c7c7c !important;
          border: 3px solid var(--primary-color) !important;
          border-width: 3px !important;
          border-radius: 15px !important;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          height: auto !important;
      }
      .form-control:focus {
         background-color: white !important;
      }
  
      .form-control ::placeholder {
          color: #ffffff !important;
      }
  
      .placeholder {
          color: #ffffff !important;
      }
  
      .action-buttons {
          display: flex;
          justify-content: center;
          margin-top: 20px;
      }
  
      .action-buttons button:hover {
          border: 3px solid var(--primary-color)!important;
          background-color: var(--primary-color) !important;
          color: var(--secondary-color) !important;
      }
  
      .action-buttons button {
          margin: 0 10px;
          border: 3px solid var(--primary-color);
          border-radius: 15px !important;
          width: 100px;
          background-color: var(--secondary-color);
          color: var(--primary-color) !important;
          font-weight: bold;
      }
  
      
  
      .form-group {
          background-color: #ffffff;
      }
  
      .guide-label {
          font-family: "Montserrat-Bold" !important;
          color: #7c7c7c !important; /* You can change the color to your preference */
          margin-bottom: 1.5rem !important; /* Adjust margin as needed */
          font-size: 17px;
      }
  
      #output {
          margin-top: 50px; /* Add top margin to the output container */
          background-color: #ffffff; /* White background color for output container */
          border: 3px solid var(--primary-color) !important;
          border-radius: 20px !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          padding-top: 40px;
          padding-bottom: 40px;
          min-height: 600px;
      }
  
      #loadingOutput {
          color: var(--primary-color);
      }
  
      #outputContent {
          margin-top: 10px; /* Add top margin to the output content */
      }
  
  </style>