<template>
  <v-container>
  <div :class="`card ${type} ${border ? '' : 'no-border'}`">
    <slot></slot>
  </div>
  </v-container>
</template>

<script>
export default {
  props: {
    border: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    type: {
      type: String
    }
  },
  data: () => {
    return {
      window: {
        width: 0,
        height: 0
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    isMobile() {
      return this.window.width < 700
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  }
}
</script>

<style scoped>
.card {
  position: relative;
  border-width: 0px;
  min-height: 0%;
  padding: 10px;

  background: #ffffff;
}

.card.no-border {
  box-shadow: none;
}

.card.banner {
  position: relative;
  min-width: 200px;
  height: 300px;
}

.card.list {
  height: 80px;
  width: 100%;
}

.card.grid {
  position: relative;
  height: 180px;
}

.card.banner.grid {
  position: relative;
  height: 180px;
  min-width: 400px;
}

@media screen and (max-width: 700px) {
  .card.banner {
    height: 100%;
  }

  .card.banner.grid {
    height: 90%;
  }
}

@media screen and (max-width: 450px) {

  .card.banner.grid {
    height: 95%;
    min-width: 200px;
  }
}


@media screen and (max-width: 400px) {

  .card.banner.grid {
    height: 95%;
    min-width: 250px;
  }

  .card.banner.grid button {
    margin-top: 30px;
  }
}

@media screen and (max-width: 300px) {
  .card.list {
    height: 70px;
  }
}
</style>
