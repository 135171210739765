<template>
  <DialogAll :dialog="dialog" :width="600" @closeDialog="closeDialog">
    <div class="mx-auto text-center" style="background: white; height: 88vh; overflow: scroll;">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <v-tabs vertical v-model="currentTab">
              <v-tab>Command</v-tab>
                <v-tab-item class="text-left">
                  <h3 class="title_label">What do you want to do?</h3>
                  <v-textarea label="Assistant Description" outlined v-model="assistantDescription"></v-textarea>
                  <v-btn color="primary" v-if="loading == false" @click="callAI(generateCommand(assistantDescription), handleCreateAssistantOutput)">Create Assistant</v-btn>
                  <div v-else>
                    <v-progress-circular indeterminate></v-progress-circular>
                  </div>
                </v-tab-item>
              <v-tab>Fill Up</v-tab>
                <v-tab-item class="text-left">
                  <h3 class="title_label">Fill up information</h3>

                  <v-row style="margin-bottom: -50px;">
                    <v-col cols="10">
                      <v-textarea label="Name" outlined v-model="newItem.name"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateName(newItem), output => newItem.name = output)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <v-row style="margin-bottom: -30px;">
                    <v-col cols="10">
                      <v-textarea label="Command" outlined v-model="newItem.command"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateCommand(assistantDescription), handleCreateAssistantOutput)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <h3 class="title_label">Additional Information</h3>
                                    
                  <v-row  style="margin-bottom: -50px;">
                    <v-col cols="10">
                      <v-textarea label="Main Category" outlined v-model="newItem.mainCategory"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateMainCategory(newItem), output => newItem.mainCategory = output)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <v-row  style="margin-bottom: -50px;">
                    <v-col cols="10">
                      <v-textarea label="Sub Category" outlined v-model="newItem.subCategory"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateSubCategory(newItem), output => newItem.subCategory = output)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <v-row style="margin-bottom: -50px;">
                    <v-col cols="10">
                      <v-textarea label="Type" outlined v-model="newItem.type"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateType(newItem), output => newItem.type = output)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <v-row style="margin-bottom: -50px;">
                    <v-col cols="10">
                      <v-textarea label="Description" outlined v-model="newItem.description"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateDescription(newItem), output => newItem.description = output)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <v-row style="margin-bottom: -50px;">
                    <v-col cols="10">
                      <v-textarea label="Tips" outlined v-model="newItem.tips"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateTips(newItem), output => newItem.tips = output)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <v-row style="margin-bottom: -50px;">
                    <v-col cols="10">
                      <v-textarea label="Tags" outlined v-model="newItem.tags"></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="callAI(generateTags(newItem), output => newItem.tags = output)">✨</v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-btn color="primary" class="mr-2" @click="addItemToTable(newItem)">Update</v-btn>
                      <v-btn color="primary" @click="clearFormFields">Clear Fields</v-btn>
                    </v-col>
                  </v-row>
                </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </DialogAll>
</template>

<script>
import DialogAll from '@/components/DialogAll.vue'
import { generateName, generateMainCategory, generateSubCategory, 
  generateType, generateDescription, generateTips, generateTags, generateCommand } from "@/utils";

import { updateCommands } from '@/api/command.js'
import { addHistory } from '@/api/history.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    },
  },
  data: () => {
    return {
      generateName,
      generateMainCategory,
      generateSubCategory, 
      generateType,
      generateDescription,
      generateTips,
      generateTags,
      generateCommand,

      updateCommands,
      addHistory,
      currentTab: 1,
      assistantDescription: '',

      newItem: {
        name: '',
        mainCategory: '',
        subCategory: '',
        type: '',
        description: '',
        command: '',
        tips: '',
        tags: '',
        id: '',
        magus_ai_id: '',
      },

      name: '',
      main_category: '',
      sub_category: '',
      description: '',
      tags: '',
      type: '',
      tips: '',
      command: '',
      history: [],
      loading: false,

    }
  },
  components: {
    DialogAll
  },
  computed: {
    secretKey() {
      return localStorage.getItem('secret');
    },
    user() {
      return this.$store.state.user.data.user;
    },
    modelSelection() {
      return localStorage.getItem('model');
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.newItem.name = this.data.name
      this.newItem.mainCategory = this.data.main_category
      this.newItem.subCategory = this.data.sub_category
      this.newItem.description = this.data.description
      this.newItem.tags = this.data.tags
      this.newItem.command = this.data.command
      this.newItem.magus_ai_id = this.data.magus_ai_id
      this.newItem.tips = this.data.tips
      this.newItem.type = this.data.type
      this.newItem.id = this.data.id
    },
    handleCreateAssistantOutput(output) {
      if(this.assistantDescription != ''){

        this.loading = true
        this.newItem.command = output;
        setTimeout(() => {
          this.loading = false
          this.currentTab = 1;
        }, 3500);

        let user = localStorage.getItem('userID');
        const data = {
          user_id: user,
          command: output,
        };
        this.addHistory(data).then((res) => {
          console.log("Save to History")
        })
      }
    },
    callAI(prompt, outputHandler) {
      if (!this.secretKey) {
        alert("Please enter the OpenAI secret key.");
        return;
      }
      if (!prompt){
        return;
      }
      const apiUrl = "https://api.openai.com/v1/chat/completions";

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          "Authorization":"Bearer " + this.secretKey,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "model": this.modelSelection,
          "messages": [
            {"role": "system", "content": "You are a helpful assistant."},
            {"role": "user", "content": prompt}
          ],
          "max_tokens": 4096
        })
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      }).then(data => {
        const aiOutput = data.choices[0].message.content.trim();
        outputHandler(aiOutput);
        this.addToHistory(aiOutput);
      }).catch(error => {
        outputHandler("Error: " + error.message);
      });
    }, 
    clearFields() {
      this.inputContent = '';
      this.placeholders = [];
      this.additionalParameters = [];
      this.newParameterName = '';
      this.updatedPrompt = '';
      this.AIOutput = '';
      this.currentTab = 0;
    },
    clearFormFields() {
      this.newItem.name = '';
      this.newItem.command = '';
      this.newItem.mainCategory = '';
      this.newItem.subCategory = '';
      this.newItem.type = '';
      this.newItem.description = '';
      this.newItem.tips = '';
      this.newItem.tags = '';
    },
    addToHistory(output) {
      if (output) {
        this.history.push(output);
      }
    },
    addItemToTable(newItem) {
      if(newItem.command){
        const data = {
          name: newItem.name,
          main_category: newItem.mainCategory,
          sub_category: newItem.subCategory,
          description: newItem.description,
          tags: newItem.tags,
          command: newItem.command,
          type: newItem.type,
          tips: newItem.tips,
          magus_ai_id: newItem.magus_ai_id,
          id: newItem.id,
        };
        this.updateCommands(data).then((res) => {
          this.$store.dispatch("command/getData", res)
          this.closeDialog();      
        })
        this.clearFormFields(); 
      }else{
        alert("Please fill in the 'Command' field first.");
        return;
      }
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
  }
}
</script>

<style>
.text_field {
  margin-bottom: -15px !important;
}
</style>