<template>
  <div>
    <PureGold />
  </div>
</template>

<script>
import PureGold from '../views/PureGold/index'
export default {
  components: {
    PureGold
  },
}
</script>