<template>
  <div class="pa-3" style="background: #F0F0F0;">
    <HeaderFree />
    <Free />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import HeaderFree from '@/components/Header/HeaderFree'
import Free from '@/views/AiWriter/Free/index.vue'
export default {
  components: {
    Free,
    Footer,
    HeaderFree
  },
  data: () => {
    return {}
  }
}
</script>
