<template>
  <div class="pa-3" style="background: #F0F0F0;">
    <HeaderAdmin />
    <Admin />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import HeaderAdmin from '@/components/Header/HeaderAdmin'
import Admin from '@/views/AiWriter/Admin/index.vue'
export default {
  components: {
    Admin,
    Footer,
    HeaderAdmin
  },
  data: () => {
    return {}
  }
}
</script>
